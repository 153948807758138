define("ui-nela/components/ui-navbar", ["exports", "ui-nela/templates/components/ui-navbar"], function (_exports, _uiNavbar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _uiNavbar.default,
    tagName: 'nav',
    classNames: ['ui-navbar', 'navbar', 'navbar-dark', 'navbar-expand-lg']
  });
});