define("ui-nela/templates/components/ui-sidebar/logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "AUaeLMaX",
    "block": "{\"symbols\":[\"@onClickLogo\",\"@linkTo\",\"@toggleMenu\"],\"statements\":[[6,[37,1],[[32,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"link-to\",[[24,0,\"ui-sidebar__sidebar__logo\"]],[[\"@route\"],[[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"img\"],[14,\"src\",\"https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grfjqezlbdq4/b/nela-cdn/o/images/nela-withe-symbol.png\"],[14,\"alt\",\"Nela\"],[12],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[11,\"div\"],[24,0,\"ui-sidebar__sidebar__logo\"],[24,\"role\",\"button\"],[4,[38,0],[\"click\",[32,1]],null],[12],[2,\"\\n    \"],[10,\"img\"],[14,\"src\",\"https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grfjqezlbdq4/b/nela-cdn/o/images/nela-withe-symbol.png\"],[14,\"alt\",\"Nela\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[11,\"div\"],[24,0,\"ui-sidebar__sidebar__logo ui-sidebar__sidebar__logo--responsive\"],[24,\"role\",\"button\"],[4,[38,0],[\"click\",[32,3]],null],[12],[2,\"\\n  \"],[10,\"img\"],[14,\"src\",\"https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grfjqezlbdq4/b/nela-cdn/o/images/nela-withe-symbol.png\"],[14,\"alt\",\"Nela\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\",\"if\"]}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-sidebar/logo.hbs"
    }
  });
});