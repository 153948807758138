define("nela-dataloader/services/store", ["exports", "@ember-data/store", "ember-inflector"], function (_exports, _store, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let StoreService = _exports.default = (_dec = Ember.inject.service, _class = class StoreService extends _store.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "fetch", _descriptor, this);
    }
    loadRecord(modelName, endpoint, options) {
      return this.fetch.request(endpoint, options).then(response => {
        let camelizeKey = Ember.String.camelize(modelName);
        let dasherizeKey = Ember.String.dasherize(modelName);
        let meta = response.meta;
        if (meta) {
          delete response.meta;
        }
        this.pushPayload(response);
        let responseRecord = response[camelizeKey] || response[dasherizeKey];
        if (!responseRecord) {
          throw 'Fomato dos dados de retorno é inválido. Root do JSON não encontrado';
        }
        let normalized = this.peekRecord(modelName, responseRecord.id);
        if (meta) {
          normalized.meta = meta;
        }
        return normalized;
      }).catch(e => {
        Ember.debug(`Nela Dataloader :: loadRecord :: ${e}`);
        throw e;
      });
    }
    loadRecords(modelName, endpoint, options) {
      return this.fetch.request(endpoint, options).then(response => {
        let model = (0, _emberInflector.pluralize)(modelName);
        let camelizeKey = Ember.String.camelize(model);
        let dasherizeKey = Ember.String.dasherize(model);
        if (Ember.isArray(response)) {
          response = {
            [`${camelizeKey}`]: response
          };
        }
        let responseArray = response[camelizeKey] || response[dasherizeKey] || response[modelName];
        if (!responseArray) {
          throw 'Formato dos dados de retorno é inválido. Root do JSON não encontrado';
        }
        let meta = response.meta;
        if (meta) {
          delete response.meta;
        }
        this.pushPayload(response);
        let normalizedResponse = responseArray.map(obj => {
          return this.peekRecord(modelName, obj.id);
        });
        if (meta) {
          normalizedResponse.meta = meta;
        }
        return normalizedResponse;
      }).catch(e => {
        Ember.debug(`Nela Dataloader :: loadRecords :: ${e}`);
        throw e;
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});