define("ui-nela/templates/components/ui-skeleton/render", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "0GpQgI9e",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,0,[\"counter\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-skeleton/render.hbs"
    }
  });
  _exports.default = _default;
});