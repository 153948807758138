define("nela-select/components/nela-select-multiple", ["exports", "nela-select/components/nela-select-basic", "nela-select/templates/components/nela-select-multiple", "ember-concurrency"], function (_exports, _nelaSelectBasic, _nelaSelectMultiple, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _nelaSelectBasic.default.extend({
    layout: _nelaSelectMultiple.default,
    classNames: ['nela-select-multiple'],
    // Methods
    onChange(selected, select) {
      if (this.modelName) {
        this.findEmberDataRecord.perform(...arguments);
      } else {
        // Impede a seleção de registros repetidos
        if (!this.allowRepeated && selected && selected.uniqBy) {
          selected = selected.uniqBy('id');
        }
        Ember.set(this, 'selected', selected);

        // Chama um hook customizado.
        if (this.afterChange) {
          this.afterChange(...arguments);
        }
      }
      select.actions.search('');
    },
    // Concurrency Tasks
    findEmberDataRecord: (0, _emberConcurrency.task)(function* (selected, publicAPI) {
      let oldSelected = publicAPI.selected || [];
      let highlighted = publicAPI.highlighted;
      let emberDataRecord;
      if (highlighted) {
        // Selecionando um novo item, busca do registro Ember Data e adiciona no array.
        emberDataRecord = yield this.store.findRecord(this.modelName, highlighted.id);
        oldSelected.addObject(emberDataRecord);
        Ember.set(this, 'selected', oldSelected);
      } else {
        // Removendo item do select, apenas atualiza selected com os dados recebidos.
        Ember.set(this, 'selected', selected);
      }

      // Chama um hook customizado.
      if (this.afterChange) {
        this.afterChange(emberDataRecord, ...arguments);
      }
    })
  });
  _exports.default = _default;
});