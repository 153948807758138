define("ui-nela/components/ui-navbar/nav/item/button", ["exports", "ui-nela/templates/components/ui-navbar/nav/item/button"], function (_exports, _button) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    layout: _button.default,
    tagName: 'button',
    classNames: ['ui-navbar__nav__btn'],
    attributeBindings: ['data-toggle', 'aria-haspopup', 'aria-expanded'],
    'data-toggle': 'dropdown',
    'aria-haspopup': 'true',
    'aria-expanded': 'false',
    didInsertElement() {
      this._super(...arguments);
      this.set('usesContextualButton', true);
    },
    willDestroyElement() {
      this._super(...arguments);
      this.set('usesContextualButton', false);
    }
  });
  _exports.default = _default;
});