define("ui-nela/templates/components/ui-button-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "NwWjHgay",
    "block": "{\"symbols\":[\"@link\",\"@target\",\"@btnIcon\",\"@text\",\"@linkTo\",\"@params\"],\"statements\":[[6,[37,1],[[32,5]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,1],[[32,6]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,0],null,[[\"route\",\"model\"],[[32,5],[32,6]]],[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"i\"],[15,0,[31,[\"fa \",[32,3]]]],[12],[13],[2,\" \"],[1,[32,4]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"link-to\",[],[[\"@route\"],[[32,5]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"i\"],[15,0,[31,[\"fa \",[32,3]]]],[12],[13],[2,\" \"],[1,[32,4]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"a\"],[15,6,[32,1]],[15,\"target\",[32,2]],[12],[2,\"\\n    \"],[10,\"i\"],[15,0,[31,[\"fa \",[32,3]]]],[12],[13],[2,\" \"],[1,[32,4]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"link-to\",\"if\"]}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-button-link.hbs"
    }
  });
  _exports.default = _default;
});