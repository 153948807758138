define("nela-datatable/components/nela-datatable-loadmore", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if (and @limit @total)}}
    <div class="nela-datatable-loadmore">
      {{#if (lt @limit @total)}}
        <p class="nela-datatable-loadmore-label" onclick={{perform this.loadMore}}>Ver mais</p>
      {{/if}}
      <p class="small">{{@limit}} de {{@total}}</p>
    </div>
  {{/if}}
  
  */
  {
    "id": "yBtN71nc",
    "block": "{\"symbols\":[\"@total\",\"@limit\"],\"statements\":[[6,[37,2],[[30,[36,3],[[32,2],[32,1]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"nela-datatable-loadmore\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[32,2],[32,1]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"p\"],[14,0,\"nela-datatable-loadmore-label\"],[15,\"onclick\",[30,[36,0],[[32,0,[\"loadMore\"]]],null]],[12],[2,\"Ver mais\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[10,\"p\"],[14,0,\"small\"],[12],[1,[32,2]],[2,\" de \"],[1,[32,1]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"perform\",\"lt\",\"if\",\"and\"]}",
    "meta": {
      "moduleName": "nela-datatable/components/nela-datatable-loadmore.hbs"
    }
  });
  let NelaDatatableLoadmoreComponent = _exports.default = (_class = class NelaDatatableLoadmoreComponent extends _component.default {
    constructor(owner, args) {
      super(owner, args);
    }
    *loadMore() {
      let onLoadMore = this.args.onLoadMore;
      yield onLoadMore();
    }
  }, _applyDecoratedDescriptor(_class.prototype, "loadMore", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadMore"), _class.prototype), _class);
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, NelaDatatableLoadmoreComponent);
});