define("ember-file-upload/components/file-upload/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ILTgxQwv",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"input\"],[15,1,[34,0]],[15,\"accept\",[34,1]],[15,\"capture\",[34,2]],[15,\"multiple\",[34,3]],[15,\"disabled\",[34,4]],[15,\"onchange\",[30,[36,5],[[32,0],\"change\"],[[\"value\"],[\"target.files\"]]]],[14,\"hidden\",\"\"],[14,4,\"file\"],[12],[13],[18,1,[[35,6]]]],\"hasEval\":false,\"upvars\":[\"for\",\"accept\",\"capture\",\"multiple\",\"disabled\",\"action\",\"queue\"]}",
    "meta": {
      "moduleName": "ember-file-upload/components/file-upload/template.hbs"
    }
  });
});