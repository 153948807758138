define("ui-nela/templates/components/ui-sidebar/menu-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "MfCm/57Z",
    "block": "{\"symbols\":[\"@link\",\"@icon\",\"@text\",\"@linkTo\",\"&default\"],\"statements\":[[6,[37,0],[[32,0,[\"show\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,0],[[32,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"link-to\",[[24,0,\"ui-sidebar__sidebar__menu__item__link\"]],[[\"@route\"],[[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"i\"],[15,0,[31,[\"ui-sidebar__sidebar__menu__item__icon fa \",[32,2]]]],[15,\"title\",[32,3]],[12],[13],[2,\"\\n      \"],[10,\"span\"],[14,0,\"ui-sidebar__sidebar__menu__item__title text-truncate\"],[12],[1,[32,3]],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"a\"],[15,6,[32,1]],[14,0,\"ui-sidebar__sidebar__menu__item__link\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[2,\"\\n      \"],[10,\"i\"],[15,0,[31,[\"ui-sidebar__sidebar__menu__item__icon fa \",[32,2]]]],[15,\"title\",[32,3]],[12],[13],[2,\"\\n      \"],[10,\"span\"],[14,0,\"ui-sidebar__sidebar__menu__item__title text-truncate\"],[12],[1,[32,3]],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[18,5,[[30,[36,3],null,[[\"submenu\"],[[30,[36,2],[\"ui-sidebar/menu-item/submenu\"],[[\"didRender\"],[[30,[36,1],[[32,0],\"onUseSubmenu\"],null]]]]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"action\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-sidebar/menu-item.hbs"
    }
  });
});