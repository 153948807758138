define("ui-nela/templates/components/ui-modal/footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "N43VgrNT",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,2],null,[[\"submit\",\"cancel\"],[[30,[36,1],[\"ui-button\"],[[\"role\",\"defaultText\"],[\"save\",true]]],[30,[36,1],[\"ui-button\"],[[\"role\",\"defaultText\",\"click\"],[\"cancel\",true,[30,[36,0],[[32,0],\"closeModal\"],null]]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-modal/footer.hbs"
    }
  });
});