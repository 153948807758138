define("ui-nela/templates/components/ui-form/checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "9tbH53o7",
    "block": "{\"symbols\":[\"@form\",\"@value\",\"@disabled\",\"@indeterminate\",\"@name\",\"@tabindex\",\"@autofocus\",\"@text\",\"&default\"],\"statements\":[[10,\"label\"],[12],[2,\"\\n  \"],[8,\"input\",[[16,\"autofocus\",[32,7]]],[[\"@type\",\"@form\",\"@checked\",\"@disabled\",\"@indeterminate\",\"@change\",\"@name\",\"@tabindex\"],[\"checkbox\",[32,1],[32,2],[32,3],[32,4],[30,[36,0],[[32,0],\"onChange\"],null],[32,5],[32,6]]],null],[2,\"\\n\\n  \"],[10,\"span\"],[14,0,\"checkbox-material\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"check\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[1,[30,[36,2],[[27,[32,9]],[35,1],[32,8]],null]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"yield\",\"if\"]}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-form/checkbox.hbs"
    }
  });
  _exports.default = _default;
});