define("ui-nela/components/load-records", ["exports", "ui-nela/templates/components/load-records", "ember-concurrency"], function (_exports, _loadRecords, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    layout: _loadRecords.default,
    tagName: '',
    store: Ember.inject.service(),
    query: (0, _emberConcurrency.task)(function* () {
      return yield this.get('store').query(this.modelName, this.params || {});
    }).on('didInsertElement')
  });
  _exports.default = _default;
});