define("ui-nela/components/ui-navbar/nav/search", ["exports", "ui-nela/templates/components/ui-navbar/nav/search"], function (_exports, _search) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _search.default,
    classNames: ['ui-navbar__search']
  });
});