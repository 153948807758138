define("ui-nela/templates/components/ui-form/radio-group/radio-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Be/y+vzk",
    "block": "{\"symbols\":[\"@name\",\"@value\",\"@text\",\"&default\"],\"statements\":[[8,\"input\",[],[[\"@type\",\"@name\",\"@value\",\"@click\"],[\"radio\",[32,1],[32,2],[30,[36,0],[[32,0],\"selectRadio\",[32,2]],null]]],null],[2,\"\\n\\n\"],[10,\"span\"],[14,0,\"circle mt-1\"],[12],[13],[2,\"\\n\"],[10,\"span\"],[14,0,\"check mt-1\"],[12],[13],[2,\"\\n\\n\"],[1,[30,[36,2],[[27,[32,4]],[35,1],[32,3]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"yield\",\"if\"]}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-form/radio-group/radio-button.hbs"
    }
  });
  _exports.default = _default;
});