define("nela-datatable/components/nela-datatable-search", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="ml-2 mt-2 float-right">
    <div class="nela-datatable-input-group">
      <input class="form-control nela-datatable-input" value={{value}} oninput={{action "onInput" value="target.value"}} placeholder="Pesquisar...">
      {{#if value}}
        <button onclick={{action "clearSearch"}} class="btn nela-datatable-input-btn" title="Limpar Pesquisa">
          <i class="fa fa-times"></i>
        </button>
      {{else}}
        <button class="btn nela-datatable-input-btn">
          <i class="fa fa-search"></i>
        </button>
      {{/if}}
    </div>
  </div>
  */
  {
    "id": "OzX6m55c",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"ml-2 mt-2 float-right\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"nela-datatable-input-group\"],[12],[2,\"\\n    \"],[10,\"input\"],[14,0,\"form-control nela-datatable-input\"],[15,2,[34,1]],[15,\"oninput\",[30,[36,0],[[32,0],\"onInput\"],[[\"value\"],[\"target.value\"]]]],[14,\"placeholder\",\"Pesquisar...\"],[12],[13],[2,\"\\n\"],[6,[37,2],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"button\"],[15,\"onclick\",[30,[36,0],[[32,0],\"clearSearch\"],null]],[14,0,\"btn nela-datatable-input-btn\"],[14,\"title\",\"Limpar Pesquisa\"],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"fa fa-times\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"button\"],[14,0,\"btn nela-datatable-input-btn\"],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"fa fa-search\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"value\",\"if\"]}",
    "meta": {
      "moduleName": "nela-datatable/components/nela-datatable-search.hbs"
    }
  });
  let NelaDatatableSearchComponent = _exports.default = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _class = class NelaDatatableSearchComponent extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "value", _descriptor, this);
    }
    onInput(value) {
      Ember.set(this, 'value', value);
      let onInput = this.args.onInput;
      onInput(value);
    }
    clearSearch() {
      Ember.set(this, 'value', '');
      let onInput = this.args.onInput;
      onInput(null);
    }
    onSearch() {
      let onInput = this.args.onInput;
      onInput(this.value);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "value", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onInput", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "onInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearSearch", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "clearSearch"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSearch", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "onSearch"), _class.prototype), _class);
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, NelaDatatableSearchComponent);
});