define("nela-mask/components/nela-input-mask", ["exports", "nela-mask/templates/components/nela-input-mask", "vanilla-masker"], function (_exports, _nelaInputMask, _vanillaMasker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.TextField.extend({
    layout: _nelaInputMask.default,
    autocomplete: 'off',
    // Life Cycle
    didInsertElement() {
      this._super(...arguments);
      let {
        element
      } = this;
      (0, _vanillaMasker.default)(element).maskPattern(this.pattern);
      element.addEventListener('input', this.inputHandler.bind(this), false);
    },
    didRender() {
      this._super(...arguments);
      if (this.unmasked) {
        this.element.value = _vanillaMasker.default.toPattern(this.unmasked, this.pattern);
      }
    },
    didUpdateAttrs() {
      this._super(...arguments);
      let {
        element
      } = this;
      (0, _vanillaMasker.default)(element).maskPattern(this.pattern);
    },
    // Methods
    inputHandler() {
      let {
        element
      } = this;
      var unmasked = element.value.replace(/\D/g, '');
      element.value = _vanillaMasker.default.toPattern(unmasked, this.pattern);
      Ember.set(this, 'unmasked', element.value.replace(/\D/g, ''));
    }
  });
});