define("ui-nela/components/ui-navbar/nav/modules", ["exports", "ui-nela/templates/components/ui-navbar/nav/modules"], function (_exports, _modules) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _modules.default,
    tagName: 'li',
    classNames: ['ui-navbar__nav__item', 'nav-item', 'dropdown'],
    moduleOptions: Ember.computed('companyConfig', function () {
      let companyConfig = this.companyConfig;
      return [{
        title: 'Agenda',
        type: 'agenda',
        modulePrefix: 'agenda-talentrh'
      }, {
        title: 'Gestão de Desempenho',
        type: 'avd',
        modulePrefix: 'avaliacao-talentrh'
      }, {
        title: 'Educação',
        type: 'ted',
        modulePrefix: 'ted-ui',
        validation: 'hasTEDModule'
      }, {
        title: 'Painel do Administrador',
        type: 'conta',
        modulePrefix: 'account-talentrh'
      }, {
        title: 'Pesquisas',
        type: 'pesquisa',
        modulePrefix: 'pesquisa-talentrh'
      }, {
        title: 'Projetos',
        type: 'projetos',
        modulePrefix: 'gestor-projetos'
      }, {
        title: 'Social',
        type: 'social',
        modulePrefix: 'portal-talentrh'
      }].filter(({
        validation
      }) => {
        return !validation || !companyConfig || companyConfig.get(validation);
      });
    }),
    modules: Ember.computed.filter('moduleOptions', function (item) {
      let environment = Ember.getOwner(this).factoryFor('config:environment').class;
      if (!environment) {
        return true;
      }
      return item.modulePrefix !== environment.modulePrefix;
    })
  });
});