define("ui-nela/components/ui-form", ["exports", "ui-nela/templates/components/ui-form"], function (_exports, _uiForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    layout: _uiForm.default,
    classNames: ['ui-form'],
    tagName: 'form',
    submit(event) {
      event.preventDefault();
      if (this.onSubmit) {
        this.onSubmit();
      }
    }
  });
  _exports.default = _default;
});