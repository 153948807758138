define("ui-nela/templates/components/load-records", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "lMgP2q2A",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[35,0,[\"last\",\"value\"]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"query\"]}",
    "meta": {
      "moduleName": "ui-nela/templates/components/load-records.hbs"
    }
  });
  _exports.default = _default;
});