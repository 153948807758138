define("ui-nela/components/ui-button", ["exports", "ui-nela/templates/components/ui-button"], function (_exports, _uiButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // HTML attributes
    layout: _uiButton.default,
    tagName: 'button',
    classNames: ['ui-button', 'btn'],
    classNameBindings: ['btnColor', 'btnSize'],
    type: 'button',
    attributeBindings: ['type', 'disabled', 'dataToggle:data-toggle', 'dataPlacement:data-placement', 'dataOriginalTitle:data-original-title'],
    // Multiline computed properties
    btnColor: Ember.computed('role', function () {
      return this.getRoleData('color');
    }),
    btnIcon: Ember.computed('role', function () {
      return this.getRoleData('icon');
    }),
    btnSize: Ember.computed('size', function () {
      const sizes = {
        sm: 'btn-sm',
        lg: 'btn-lg'
      };
      return sizes[Ember.get(this, 'size')] || '';
    }),
    btnText: Ember.computed('text', 'role', 'defaultText', function () {
      // Texto informado no botão
      let text = Ember.get(this, 'text');
      // Texto padrão exibido baseado na role
      let roleText = this.getRoleData('text');
      // flag que indica se é pra exibir o texto padrão da role
      let defaultText = Ember.get(this, 'defaultText');
      return defaultText ? roleText : text;
    }),
    dataToggle: Ember.computed('tooltipPlacement', 'dataOriginalTitle', function () {
      let dataOriginalTitle = Ember.get(this, 'dataOriginalTitle');
      return dataOriginalTitle ? 'tooltip' : null;
    }),
    dataPlacement: Ember.computed('tooltipPlacement', 'dataOriginalTitle', function () {
      let dataOriginalTitle = Ember.get(this, 'dataOriginalTitle');
      if (dataOriginalTitle) {
        let tooltipPlacement = Ember.get(this, 'tooltipPlacement');
        return tooltipPlacement || 'top';
      }
      return null;
    }),
    dataOriginalTitle: Ember.computed('tooltip', 'text', 'role', function () {
      // Exibe o tooltip informado manualmente.
      let tooltip = Ember.get(this, 'tooltip');
      if (tooltip) {
        return tooltip;
      }

      /* Se um botão não recebeu text e nem tooltip, exibe o tooltip padrão baseado na role,
        isso evita que botões que usam apenas icones fiquem sem uma descrição de sua funcionalidade. */
      let text = Ember.get(this, 'text');
      let defaultText = Ember.get(this, 'defaultText');
      let roleText = this.getRoleData('text');
      return !text && !defaultText ? roleText : null;
    }),
    // Custom methods
    /* Retorna as definições padrão do botão baseado na role */
    getRoleData(type) {
      // se a propriedade foi informada de forma explícita, aplica a mesma ao botão.
      let property = Ember.get(this, type);
      if (type && property) {
        return property;
      }
      const role = Ember.get(this, 'role');
      const definitions = {
        view: {
          text: 'Visualizar',
          icon: 'fa-eye'
        },
        create: {
          text: 'Criar Novo',
          icon: 'fa-plus'
        },
        save: {
          text: 'Salvar',
          icon: 'fa-save'
        },
        edit: {
          text: 'Editar',
          icon: 'fa-edit'
        },
        delete: {
          text: 'Excluir',
          icon: 'fa-trash',
          color: 'btn-danger'
        },
        cancel: {
          text: 'Cancelar',
          color: 'border-primary text-primary'
        },
        default: {
          icon: 'd-none',
          color: 'btn-primary'
        }
      };
      let roleData = definitions[role] || definitions.default;
      if (role) {
        roleData = Object.assign(definitions.default, roleData);
      }
      return type ? roleData[type] : roleData;
    }
  });
});