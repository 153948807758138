define("ui-nela/components/ui-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.LinkComponent.reopen({
    attributeBindings: ['dataToggle:data-toggle', 'dataPlacement:data-placement', 'dataOriginalTitle:data-original-title'],
    dataToggle: Ember.computed('tooltipPlacement', 'dataOriginalTitle', function () {
      let dataOriginalTitle = Ember.get(this, 'dataOriginalTitle');
      return dataOriginalTitle ? 'tooltip' : null;
    }),
    dataPlacement: Ember.computed('tooltipPlacement', 'dataOriginalTitle', function () {
      let dataOriginalTitle = Ember.get(this, 'dataOriginalTitle');
      if (dataOriginalTitle) {
        let tooltipPlacement = Ember.get(this, 'tooltipPlacement');
        return tooltipPlacement || 'top';
      }
      return null;
    }),
    dataOriginalTitle: Ember.computed('tooltip', function () {
      let tooltip = Ember.get(this, 'tooltip');
      return tooltip;
    })
  });
});