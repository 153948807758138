define("ui-nela/components/ui-form/group/status", ["exports", "ui-nela/templates/components/ui-form/group/status"], function (_exports, _status) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _status.default,
    tagName: 'small',
    classNames: ['ui-form-status', 'text-danger', 'position-absolute']
  });
});