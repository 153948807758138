define("nela-fetch/services/fetch", ["exports", "ember-concurrency-decorators", "fetch", "@ember-data/adapter/error", "ember-fetch/errors"], function (_exports, _emberConcurrencyDecorators, _fetch, _error, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let FetchService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = class FetchService extends Ember.Service {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "session", _descriptor, this);
      _initializerDefineProperty(this, "toast", _descriptor2, this);
    }
    del() {
      let url = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      options.method = 'DELETE';
      return this.fetchRequest.perform(url, options);
    }
    file() {
      let url = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return this.fileRequest.perform(url, options);
    }
    pdf() {
      let url = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return this.fileRequest.perform(url, options);
    }
    post() {
      let url = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      options.method = 'POST';
      return this.fetchRequest.perform(url, options);
    }
    put() {
      let url = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      options.method = 'PUT';
      return this.fetchRequest.perform(url, options);
    }
    request() {
      let url = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return this.fetchRequest.perform(url, options);
    }
    serialize(obj, attr) {
      let str = [];
      for (let key in obj) {
        if (obj[key] === undefined) {
          continue;
        }
        let param;
        let prefix = attr ? `${attr}[${key}]` : key;
        if (Ember.isArray(obj[key])) {
          param = obj[key].map((value, index) => {
            if (typeof value === 'object' && value !== null) {
              return this.serialize(value, `${prefix}[${index}]`);
            }
            return `${encodeURIComponent(`${prefix}[]`)}=${encodeURIComponent(value)}`;
          });
          param = param.join('&');
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
          param = this.serialize(obj[key], prefix);
        } else {
          let value = obj[key] === null ? '' : obj[key];
          param = `${encodeURIComponent(prefix)}=${encodeURIComponent(value)}`;
        }
        str.pushObject(param);
      }
      return str.join('&');
    }
    *fetch(url, options) {
      let method = options.method || 'GET';
      let headers = this.session.authorizationBearer;
      let environment = Ember.getOwner(this).factoryFor('config:environment').class;
      let host = this.host || environment.apiUrl.core;
      if (options.host) {
        if (/^http(s)*:/.test(options.host)) {
          host = options.host;
        } else {
          host = environment.apiUrl[options.host];
        }
      }
      if (host === '/api') {
        host = `${window.location.origin}/api`;
      }
      url = url.replace(/^\//g, '');
      url = `${host}/${url}`;
      let data = options.data || {};
      if (method === 'GET') {
        let query = this.serialize(data);
        if (query) {
          url += `?${query}`;
        }
      } else {
        options.body = JSON.stringify(data);
      }
      options = {
        headers,
        method,
        ...options
      };
      try {
        let response = yield (0, _fetch.default)(url, options);
        if (response.ok) {
          return response;
        }
        let errors = [{
          detail: yield response.text(),
          status: response.status,
          title: 'The backend responded with an error'
        }];
        if ((0, _errors.isUnauthorizedResponse)(response)) {
          return this.session.invalidate();
        }
        if ((0, _errors.isForbiddenResponse)(response)) {
          throw new _error.ForbiddenError(errors);
        }
        if ((0, _errors.isNotFoundResponse)(response)) {
          throw new _error.NotFoundError(errors);
        }
        if ((0, _errors.isServerErrorResponse)(response)) {
          throw new _error.ServerError([{
            detail: 'Ocorreu um erro inesperado, tente novamente mais tarde',
            status: response.status,
            title: 'The backend responded with an error'
          }]);
        }
        throw new _error.default(errors);
      } catch (e) {
        if (e.message === 'Network request failed') {
          throw 'Falha na conexão';
        }
        throw e;
      }
    }
    *fetchRequest() {
      let response = yield this.fetch.perform(...arguments);
      if (response.status === 204) {
        return;
      }
      let content = yield response.json();
      return content;
    }
    *fileRequest() {
      let response = yield this.fetch.perform(...arguments);
      let file = yield response.blob();
      if (file.size) {
        return URL.createObjectURL(file);
      }
      this.toast.info('Nenhum registro encontrado');
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fetch", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetch"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchRequest", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchRequest"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fileRequest", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fileRequest"), _class.prototype)), _class));
  _exports.default = FetchService;
});