define("ember-simple-auth-token/mixins/token-authorizer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
    Authorizer Mixin that works with token-based authentication like JWT by sending the `token` properties from the session in the `Authorization` header.
  
    @class TokenAuthorizer
    @module ember-simple-auth-token/mixins/token-authorizer
    @extends Ember.Mixin
  */
  var _default = Ember.Mixin.create({
    session: Ember.inject.service('session'),
    /**
      @method init
    */
    init() {
      this._super(...arguments);
      const owner = Ember.getOwner(this);
      const environment = owner ? owner.resolveRegistration('config:environment') || {} : {};
      const config = environment['ember-simple-auth-token'] || {};
      this.tokenPropertyName = config.tokenPropertyName || 'token';
      this.authorizationHeaderName = config.authorizationHeaderName || 'Authorization';
      this.authorizationPrefix = config.authorizationPrefix === '' ? '' : config.authorizationPrefix || 'Bearer ';
    },
    /**
      Authorizes an XHR request by sending the `token` properties from the session in the `Authorization` header:
       ```
      Authorization: Bearer <token>
      ```
       @method authorize
      @param {XMLHttpRequest} xhr
    */
    authorize(xhr) {
      const data = Ember.get(this, 'session.data.authenticated');
      const token = Ember.get(data, this.get('tokenPropertyName'));
      const prefix = this.get('authorizationPrefix');
      const header = this.get('authorizationHeaderName');
      if (this.get('session.isAuthenticated') && !Ember.isEmpty(token)) {
        xhr.setRequestHeader(header, prefix + token);
      }
    },
    /**
      Handles response from server.
       @method authorize
      @param {Number} status
    */
    handleResponse(status) {
      if (status === 401 && this.get('session.isAuthenticated')) {
        this.get('session').invalidate();
      }
      return this._super(...arguments);
    }
  });
  _exports.default = _default;
});