define("ui-nela/templates/components/ui-navbar/nav/dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "sQqrESCh",
    "block": "{\"symbols\":[\"@user\",\"&default\"],\"statements\":[[10,\"ul\"],[14,0,\"ui-navbar__nav__dropdown\"],[12],[2,\"\\n  \"],[18,2,[[30,[36,1],null,[[\"item\",\"status\"],[[30,[36,0],[\"ui-navbar/nav/dropdown/item\"],null],[30,[36,0],[\"ui-navbar/nav/user/status\"],[[\"user\"],[[32,1]]]]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-navbar/nav/dropdown.hbs"
    }
  });
});