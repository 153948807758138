define("ui-nela/components/ui-radio", ["exports", "ui-nela/templates/components/ui-radio"], function (_exports, _uiRadio) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // HTML attributes
    layout: _uiRadio.default,
    classNames: ['ui-radio'],
    // option - passed in, required, the value for this radio button
    // selected - passed in, required, the currently selected value

    // optionally passed in:
    // disabled - boolean
    // required - boolean
    // name - string
    // radioClass - string
    // radioId - string
    // ariaLabelledby - string
    // ariaDescribedby - string

    // Multiline computed properties
    checked: Ember.computed('selected', 'option', function () {
      return Ember.isEqual(this.selected, this.option);
    }).readOnly(),
    // Lifecycle attributes
    init() {
      this._super(...arguments);
      (true && !(this.option !== undefined || this.disabled) && Ember.assert('<UiRadio> requires an `@option` when not `disabled`', this.option !== undefined || this.disabled));
    },
    // Actions
    actions: {
      onChange(selected) {
        if (this.onChange) {
          this.onChange(selected);
        }
      }
    }
  });
  _exports.default = _default;
});