define("ember-power-select/components/power-select-multiple", ["exports", "@ember-decorators/component", "ember-power-select/templates/components/power-select-multiple", "ember-power-select/utils/computed-fallback-if-undefined"], function (_exports, _component, _powerSelectMultiple, _computedFallbackIfUndefined) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _class2, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let PowerSelectMultiple = _exports.default = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_powerSelectMultiple.default), _dec3 = (0, _computedFallbackIfUndefined.default)('power-select-multiple/trigger'), _dec4 = (0, _computedFallbackIfUndefined.default)(null), _dec5 = Ember.computed('triggerClass'), _dec6 = Ember.computed, _dec7 = Ember.computed('tabindex', 'searchEnabled', 'triggerComponent'), _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = (0, _computedFallbackIfUndefined.default)(function (option, select) {
    let newSelection = (select.selected || []).slice(0);
    let idx = -1;
    for (let i = 0; i < newSelection.length; i++) {
      if (Ember.isEqual(newSelection[i], option)) {
        idx = i;
        break;
      }
    }
    if (idx > -1) {
      newSelection.splice(idx, 1);
    } else {
      newSelection.push(option);
    }
    return newSelection;
  }), _dec(_class = _dec2(_class = (_class2 = class PowerSelectMultiple extends Ember.Component {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "triggerComponent", _descriptor, this);
      _initializerDefineProperty(this, "beforeOptionsComponent", _descriptor2, this);
      // Methods
      _initializerDefineProperty(this, "buildSelection", _descriptor3, this);
    }
    // CPs
    get concatenatedTriggerClass() {
      let classes = ['ember-power-select-multiple-trigger'];
      if (this.triggerClass) {
        classes.push(this.triggerClass);
      }
      return classes.join(' ');
    }
    get selected() {
      return [];
    }
    set selected(v) {
      if (v === null || v === undefined) {
        return [];
      }
      return v;
    }
    get computedTabIndex() {
      if (this.triggerComponent === 'power-select-multiple/trigger' && this.searchEnabled) {
        return '-1';
      } else {
        return this.tabindex || '0';
      }
    }

    // Actions
    handleOpen(select, e) {
      if (this.onOpen && this.onOpen(select, e) === false) {
        return false;
      }
      this.focusInput(select);
    }
    handleFocus(select, e) {
      if (this.onFocus) {
        this.onFocus(select, e);
      }
      this.focusInput(select);
    }
    handleKeydown(select, e) {
      if (this.onKeydown && this.onKeydown(select, e) === false) {
        e.stopPropagation();
        return false;
      }
      if (e.keyCode === 13 && select.isOpen) {
        e.stopPropagation();
        if (select.highlighted !== undefined) {
          if (!select.selected || select.selected.indexOf(select.highlighted) === -1) {
            select.actions.choose(select.highlighted, e);
            return false;
          } else {
            select.actions.close(e);
            return false;
          }
        } else {
          select.actions.close(e);
          return false;
        }
      }
    }
    focusInput(select) {
      if (select) {
        let input = document.querySelector(`#ember-power-select-trigger-multiple-input-${select.uniqueId}`);
        if (input) {
          input.focus();
        }
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "triggerComponent", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "beforeOptionsComponent", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "concatenatedTriggerClass", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "concatenatedTriggerClass"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "selected", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "selected"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "computedTabIndex", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "computedTabIndex"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleOpen", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "handleOpen"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleFocus", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "handleFocus"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleKeydown", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "handleKeydown"), _class2.prototype), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "buildSelection", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class2)) || _class) || _class);
});