define("ui-nela/components/ui-navbar/nav/dropdown", ["exports", "ui-nela/templates/components/ui-navbar/nav/dropdown"], function (_exports, _dropdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    layout: _dropdown.default,
    classNames: ['dropdown-menu', 'dropdown-menu-width']
  });
  _exports.default = _default;
});