define("ui-nela/helpers/app-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AppUrl extends Ember.Helper {
    compute([app, route]) {
      const environment = Ember.getOwner(this).factoryFor('config:environment').class;
      return environment.appUrl[app] + (route || '');
    }
  }
  _exports.default = AppUrl;
});