define("nela-report/services/report", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    pdf() {
      let url = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const enviroment = Ember.getOwner(this).factoryFor('config:environment').class;
      const token = this.get('session.session.content.authenticated.token');
      const headers = {
        'Authorization': `Bearer ${token}`
      };
      const optionsDefault = {
        headers
      };
      options = Object.assign({}, options, optionsDefault);
      return fetch(enviroment.apiBaseUrl + url, options).then(data => {
        if (data.status === 204) {
          return;
        }
        return data.blob();
      }).then(report => {
        if (report) {
          return URL.createObjectURL(report);
        }
        return null;
      });
    }
  });
  _exports.default = _default;
});