define("ui-nela/components/ui-checkbox/input", ["exports", "ui-nela/templates/components/ui-checkbox/input"], function (_exports, _input) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    layout: _input.default,
    tagName: 'input',
    attributeBindings: Ember.A(['checked', 'value', 'checkedStr:aria-checked']),
    checkedStr: Ember.computed('checked', function () {
      return typeof this.checked === 'boolean' ? this.checked.toString() : null;
    }),
    didInsertElement() {
      this._super(...arguments);
      this.element.addEventListener('change', e => {
        if (this.checked !== undefined) {
          e.target.checked = this.checked;
        }
      });
    },
    change(proxyEvent) {
      this.onChange(this.value, proxyEvent);
    }
  });
  _exports.default = _default;
});