define("ui-nela/components/ui-skeleton", ["exports", "ui-nela/templates/components/ui-skeleton"], function (_exports, _uiSkeleton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _uiSkeleton.default,
    classNames: ['ui-skeleton']
  });
});