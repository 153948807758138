define("ui-nela/templates/components/ui-crud/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ZWNzNTWg",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"ui-crud__scroll\"],[12],[2,\"\\n  \"],[18,1,[[30,[36,1],null,[[\"header\",\"listGroup\"],[[30,[36,0],[\"ui-crud/list/header\"],null],[30,[36,0],[\"ui-crud/list/list-group\"],null]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-crud/list.hbs"
    }
  });
  _exports.default = _default;
});