define("ui-nela/templates/components/ui-form-renderer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "HGSfolEv",
    "block": "{\"symbols\":[\"@formName\",\"&default\"],\"statements\":[[18,2,[[30,[36,3],null,[[\"content\",\"submit\",\"cancel\"],[[30,[36,0],[[32,1]],[[\"onLoadForm\"],[[30,[36,2],[[32,0],\"onLoadForm\"],null]]]],[30,[36,0],[\"ui-button\"],[[\"text\",\"icon\",\"click\"],[\"Salvar\",\"fa-save\",[30,[36,1],[[32,0,[\"submitTask\"]]],null]]]],[30,[36,0],[\"ui-button-link\"],[[\"tagName\",\"text\",\"role\",\"class\"],[\"button\",\"Cancelar\",\"cancel\",\"mr-3\"]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"perform\",\"action\",\"hash\"]}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-form-renderer.hbs"
    }
  });
});