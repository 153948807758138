define("ui-nela/templates/components/ui-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "INvbCl4p",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,0],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"i\"],[15,0,[31,[\"fa \",[32,0,[\"btnIcon\"]],\" \",[30,[36,0],[[32,0,[\"btnText\"]],\"pr-1\"],null]]]],[12],[13],[2,\"\\n  \"],[1,[32,0,[\"btnText\"]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-button.hbs"
    }
  });
  _exports.default = _default;
});