define("nela-dataloader/components/nela-paging", ["exports", "nela-dataloader/templates/components/nela-paging", "ember-concurrency"], function (_exports, _nelaPaging, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    layout: _nelaPaging.default,
    tagName: '',
    // Services
    fetch: Ember.inject.service(),
    store: Ember.inject.service(),
    // Computed
    hideEmpty: Ember.computed.or('loadRecords.isRunning', 'records.length'),
    hideLoading: Ember.computed.alias('loadRecords.isIdle'),
    hideLoadMore: Ember.computed.or('loadRecords.isRunning', 'endOfRecords'),
    // Life Cycle
    init() {
      this._super(...arguments);
      this.skip = this.skip || 0;
      this.limit = this.limit || 10;
      this.records = this.records || [];
      this.sort = this.sort || 'createdAt DESC';
    },
    didInsertElement() {
      this._super(...arguments);
      this.loadRecords.perform();
    },
    didUpdateAttrs() {
      this._super(...arguments);
      if (this.reload !== this.lastReload) {
        // reload faz com que a lista seja atualizada sem resetar a paginação mantendo a mesma quantidade de registros.
        this.updateRecords.perform();
        this.lastReload = this.reload;
      } else {
        // Quando os filtros forem atualizados, reseta a paginação
        Ember.set(this, 'skip', 0);
        Ember.set(this, 'records', []);
        this.loadRecords.perform();
      }
    },
    /*
      loadRecords
      Faz a busca de registros do servidor fazendo o controle de paginação.
    */
    loadRecords: (0, _emberConcurrency.task)(function* () {
      let data = {
        ...(this.filters || {}),
        sort: this.sort,
        skip: this.skip,
        limit: this.limit
      };
      let records = yield this.findRecords.perform(data);

      // Se SKIP é igual a 0 reseta o array de registros
      if (this.skip === 0) {
        Ember.set(this, 'records', records.toArray());
        Ember.set(this, 'meta', records.meta);
      } else {
        this.records.addObjects(records.toArray());
      }

      // Quando os registros acabarem, oculta o botão "ver mais".
      let endOfRecords = records.length < this.limit || records.meta && records.meta.total === this.records.length;
      Ember.set(this, 'endOfRecords', endOfRecords);
    }).restartable(),
    /*
      updateRecords
      Atualiza a lista de registro atual com dados do servidor sem resetar a paginação.
    */
    updateRecords: (0, _emberConcurrency.task)(function* () {
      let data = {
        ...(this.filters || {}),
        sort: this.sort,
        skip: 0,
        limit: this.records.length
      };
      let records = yield this.findRecords.perform(data);
      Ember.set(this, 'records', records.toArray());
      Ember.set(this, 'meta', records.meta);
    }).restartable(),
    findRecords: (0, _emberConcurrency.task)(function () {
      var _this = this;
      let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return function* () {
        let records = [];
        let options = {
          data
        };
        if (_this.host) {
          options.host = _this.host;
        }
        try {
          if (_this.endpoint && _this.modelName) {
            records = yield _this.store.loadRecords(_this.modelName, _this.endpoint, options);
          } else if (_this.modelName) {
            records = yield _this.store.query(_this.modelName, data);
          } else if (_this.endpoint) {
            records = yield _this.fetch.request(_this.endpoint, options);
          } else {
            Ember.debug('Ops! Para utilizar o NelaPaging é necessário informar um @modelName e/ou @endpoint');
          }
        } catch (e) {
          Ember.debug(`<NelaPaging> :: loadRecords :: ${e}`);
        }
        return records;
      }();
    }).restartable(),
    // Actions
    actions: {
      loadMore() {
        this.incrementProperty('skip', this.limit);
        this.loadRecords.perform();
      }
    }
  });
  _exports.default = _default;
});