define("nela-dataloader/templates/components/nela-paging", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "/WfkeBlm",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,7],null,[[\"error\",\"loadMore\",\"loading\",\"meta\",\"records\",\"Empty\",\"Loading\",\"LoadMore\",\"PagingStatus\"],[[32,0,[\"loadRecords\",\"last\",\"error\"]],[30,[36,3],[[32,0],\"loadMore\"],null],[32,0,[\"loadRecords\",\"isRunning\"]],[32,0,[\"meta\"]],[32,0,[\"records\"]],[30,[36,2],[\"nela-paging/empty\"],[[\"hideEmpty\"],[[35,6]]]],[30,[36,2],[\"nela-paging/loading\"],[[\"hideLoading\"],[[35,5]]]],[30,[36,2],[\"nela-paging/btn-load-more\"],[[\"hideLoadMore\",\"click\"],[[35,4],[30,[36,3],[[32,0],\"loadMore\"],null]]]],[30,[36,2],[\"nela-paging/paging-status\"],[[\"records\",\"meta\"],[[35,1],[35,0]]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"meta\",\"records\",\"component\",\"action\",\"hideLoadMore\",\"hideLoading\",\"hideEmpty\",\"hash\"]}",
    "meta": {
      "moduleName": "nela-dataloader/templates/components/nela-paging.hbs"
    }
  });
  _exports.default = _default;
});