define("ui-nela/components/ui-sidebar", ["exports", "ui-nela/templates/components/ui-sidebar", "perfect-scrollbar"], function (_exports, _uiSidebar, _perfectScrollbar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // HTML attributes
    layout: _uiSidebar.default,
    classNames: ['ui-sidebar', 'not-printable'],
    // Services
    session: Ember.inject.service(),
    // Lifecycle hooks
    didInsertElement() {
      this.initSubMenu();
      this.closeMenuOnClickItem();
      this.initPerfectScrollBar();
      this.closeMenuOnPressESCEvent();
    },
    // Custom methods
    initSubMenu() {
      Ember.run.next(this, function () {
        let uiSidebar = this.element.getElementsByClassName('ui-sidebar__sidebar__menu__item--with-submenu');
        for (let itemWithSubmenu of uiSidebar) {
          itemWithSubmenu.addEventListener('mouseover', () => {
            let submenu = itemWithSubmenu.querySelector('.ui-sidebar__sidebar__menu__item__submenu');
            if (!submenu) {
              return;
            }

            /* 30px é o espaço mínimo do submenu da parte inferior da tela,
            para não causar a impressão de que parte dele está oculto */
            let windowHeight = window.innerHeight - 30;
            let submenuHeight = submenu.scrollHeight;
            let offsetTop = itemWithSubmenu.offsetTop;
            if (offsetTop + submenuHeight > windowHeight) {
              let position = windowHeight - submenuHeight - 100;
              submenu.setAttribute('style', `top: ${position}px;`);
            } else {
              submenu.setAttribute('style', `top: ${offsetTop}px;`);
            }
            this.element.classList.add('ui-sidebar--backdrop');
          });
          itemWithSubmenu.addEventListener('mouseleave', () => {
            this.element.classList.remove('ui-sidebar--backdrop');
          });
        }
      });
    },
    initPerfectScrollBar() {
      new _perfectScrollbar.default('.ui-sidebar__sidebar__menu', {
        wheelPropagation: true
      });
    },
    closeMenuOnClickItem() {
      document.addEventListener('click', e => {
        if (this.isDestroyed) {
          return;
        }
        let clickInSidebar = e.target.closest('.ui-sidebar__sidebar');
        let clickInSubMenuItem = e.target.closest('.ui-sidebar__sidebar__menu__item__submenu');
        let clickInMenuItem = e.target.closest('.ui-sidebar__sidebar__menu__item');
        if (clickInMenuItem) {
          clickInMenuItem = clickInMenuItem.classList;
          clickInMenuItem = !clickInMenuItem.contains('ui-sidebar__sidebar__menu__item--with-submenu') && !clickInMenuItem.contains('ui-sidebar__sidebar__menu__item--open');
        }

        // Fecha o sidebar se clicar em um item do menu ou fora do sidebar.
        if (!clickInSidebar || clickInMenuItem || clickInSubMenuItem) {
          this.element.querySelector('.ui-sidebar__sidebar').classList.remove('ui-sidebar__sidebar--large');
        }
      });
    },
    closeMenuOnPressESCEvent() {
      document.addEventListener('keydown', e => {
        if (e.which === 27) {
          this.element.querySelector('.ui-sidebar__sidebar').classList.remove('ui-sidebar__sidebar--large');
        }
      });
    },
    // Actions
    actions: {
      toggleMenu() {
        this.element.querySelector('.ui-sidebar__sidebar').classList.toggle('ui-sidebar__sidebar--large');
      },
      onClickLogo() {
        if (this.onClickLogo) {
          this.onClickLogo();
        }
      }
    }
  });
});