define("ui-nela/components/ui-page/header/title", ["exports", "ui-nela/templates/components/ui-page/header/title"], function (_exports, _title) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _title.default,
    classNames: ['ui-page__header__title', 'h3']
  }).reopenClass({
    positionalParams: ['title']
  });
});