define("ui-nela/components/ui-page/header/breadcrumbs", ["exports", "ui-nela/templates/components/ui-page/header/breadcrumbs"], function (_exports, _breadcrumbs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _breadcrumbs.default,
    classNames: ['ui-page__header__breadcrumbs', 'd-flex']
  });
});