define("ui-nela/components/ui-navbar/logo", ["exports", "ui-nela/templates/components/ui-navbar/logo"], function (_exports, _logo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _logo.default,
    tagName: ''
  });
});