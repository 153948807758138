define("nela-mask/components/nela-input-cep", ["exports", "nela-mask/components/nela-input-mask", "ember-concurrency"], function (_exports, _nelaInputMask, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _nelaInputMask.default.extend({
    ajax: Ember.inject.service(),
    swal: Ember.inject.service(),
    pattern: '99.999-999',
    didInsertElement() {
      this._super(...arguments);
      let {
        element
      } = this;
      element.addEventListener('input', this.onchangeInput.bind(this), false);
    },
    init() {
      this._super(...arguments);
      (true && !(this.onChange) && Ember.assert('<NelaInputCep> requires an `@onChange` function.', this.onChange));
    },
    onchangeInput() {
      this.findCep.perform();
    },
    findCep: (0, _emberConcurrency.task)(function* () {
      const {
        element,
        onChange
      } = this;
      try {
        const value = element.value;
        const validate = /^[0-9]{8}$/;
        const cep = value.replace(/\D/g, '');
        if (cep.length < 8) {
          const small = document.getElementById('cep-not-found');
          if (small) {
            element.classList.remove('border', 'border-danger');
            element.removeAttribute('style');
            small.remove();
          }
        }
        if (cep.length === 8) {
          if (validate.test(cep)) {
            const response = yield this.ajax.request(`https://viacep.com.br/ws/${cep}/json`);
            if (response.erro) {
              const existSmall = document.getElementById('cep-not-found');
              if (!existSmall) {
                const small = document.createElement('small');
                small.setAttribute('id', 'cep-not-found');
                small.classList.add('text-danger');
                small.innerHTML = 'CEP não encontrado';
                element.classList.add('border', 'border-danger');
                element.style['boxShadow'] = '0 0 5px red';
                element.parentNode.appendChild(small);
                onChange(response);
              }
            } else {
              onChange(response);
            }
          } else {
            this.swal.warning('Formato do CEP inválido');
          }
        }
      } catch (e) {
        this.swal.catch(e);
      }
    }).restartable()
  });
  _exports.default = _default;
});