define("ui-nela/components/ui-tabs", ["exports", "ui-nela/templates/components/ui-tabs"], function (_exports, _uiTabs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _uiTabs.default,
    tagName: 'ul',
    classNames: ['ui-tabs', 'nav', 'nav-tabs']
  });
});