define("ember-power-select/components/power-select-multiple/trigger", ["exports", "@ember-decorators/component", "ember-power-select/templates/components/power-select-multiple/trigger"], function (_exports, _component, _trigger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _class2, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const ua = window && window.navigator ? window.navigator.userAgent : '';
  const isIE = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;
  let Trigger = _exports.default = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_trigger.default), _dec3 = Ember.inject.service, _dec4 = Ember.computed('select.{searchText.length,selected.length}'), _dec5 = Ember.computed('placeholder', 'select.selected.length'), _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec(_class = _dec2(_class = (_class2 = class Trigger extends Ember.Component {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "textMeasurer", _descriptor, this);
      _defineProperty(this, "_lastIsOpen", false);
    }
    // CPs
    get triggerMultipleInputStyle() {
      Ember.run.scheduleOnce('actions', this.select.actions.reposition);
      if (!this.select.selected || Ember.get(this.select.selected, 'length') === 0) {
        return Ember.String.htmlSafe('width: 100%;');
      } else {
        let textWidth = 0;
        if (this.inputFont) {
          textWidth = this.textMeasurer.width(this.select.searchText, this.inputFont);
        }
        return Ember.String.htmlSafe(`width: ${textWidth + 25}px`);
      }
    }
    get maybePlaceholder() {
      if (isIE) {
        return undefined;
      }
      return !this.select.selected || Ember.get(this.select.selected, 'length') === 0 ? this.placeholder || '' : '';
    }

    // Lifecycle hooks
    didReceiveAttrs() {
      let oldSelect = this.oldSelect || {};
      this.set('oldSelect', this.select);
      if (oldSelect.isOpen && !this.select.isOpen) {
        Ember.run.scheduleOnce('actions', null, this.select.actions.search, '');
      }
    }

    // Actions
    storeInputStyles(input) {
      let {
        fontStyle,
        fontVariant,
        fontWeight,
        fontSize,
        lineHeight,
        fontFamily
      } = window.getComputedStyle(input);
      this.inputFont = `${fontStyle} ${fontVariant} ${fontWeight} ${fontSize}/${lineHeight} ${fontFamily}`;
    }
    chooseOption(e) {
      let selectedIndex = e.target.getAttribute('data-selected-index');
      if (selectedIndex) {
        e.stopPropagation();
        e.preventDefault();
        let object = this.selectedObject(this.select.selected, selectedIndex);
        this.select.actions.choose(object);
      }
    }
    handleInput(e) {
      if (this.onInput && this.onInput(e) === false) {
        return;
      }
      this.select.actions.open(e);
    }
    handleKeydown(e) {
      if (this.onKeydown && this.onKeydown(e) === false) {
        e.stopPropagation();
        return false;
      }
      if (e.keyCode === 8) {
        e.stopPropagation();
        if (Ember.isBlank(e.target.value)) {
          let lastSelection = this.select.selected[this.select.selected.length - 1];
          if (lastSelection) {
            this.select.actions.select(this.buildSelection(lastSelection, this.select), e);
            if (typeof lastSelection === 'string') {
              this.select.actions.search(lastSelection);
            } else {
              (true && !(this.searchField) && Ember.assert('`<PowerSelectMultiple>` requires a `@searchField` when the options are not strings to remove options using backspace', this.searchField));
              this.select.actions.search(Ember.get(lastSelection, this.searchField));
            }
            this.select.actions.open(e);
          }
        }
      } else if (e.keyCode >= 48 && e.keyCode <= 90 || e.keyCode === 32) {
        // Keys 0-9, a-z or SPACE
        e.stopPropagation();
      }
    }
    selectedObject(list, index) {
      if (list.objectAt) {
        return list.objectAt(index);
      } else {
        return Ember.get(list, index);
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "textMeasurer", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "triggerMultipleInputStyle", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "triggerMultipleInputStyle"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "maybePlaceholder", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "maybePlaceholder"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "storeInputStyles", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "storeInputStyles"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "chooseOption", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "chooseOption"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleInput", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "handleInput"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleKeydown", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "handleKeydown"), _class2.prototype), _class2)) || _class) || _class);
});