define("nela-dataloader/components/nela-paging/empty", ["exports", "nela-dataloader/templates/components/nela-paging/empty"], function (_exports, _empty) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _empty.default,
    classNameBindings: ['hideEmpty:d-none']
  });
});