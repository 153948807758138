define("ui-nela/components/ui-modal", ["exports", "ui-nela/templates/components/ui-modal", "jquery"], function (_exports, _uiModal, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // HTML attributes
    layout: _uiModal.default,
    classNames: 'modal',
    attributeBindings: ['role', 'tabindex', 'aria-hidden', 'data-backdrop', 'aria-labelledby'],
    // Multiline computed properties
    // eslint-disable-next-line ember/no-observers
    onOpenChange: Ember.observer('open', function () {
      this.toogleModal();
    }),
    // Lifecycle hooks
    didInsertElement() {
      this._super(...arguments);
      this.toogleModal();
    },
    willDestroyElement() {
      (0, _jquery.default)(`#${this.elementId}`).modal('hide');
    },
    // Custom methods
    toogleModal() {
      // Verifica a variável de controle, abrindo/fechando o modal
      if (this.get('open')) {
        (0, _jquery.default)(`#${this.elementId}`).modal('show');
        if (this.onOpen) {
          this.onOpen();
        }
      } else {
        (0, _jquery.default)(`#${this.elementId}`).modal('hide');
        (0, _jquery.default)(`#${this.elementId}`).on('hide.bs.modal', () => {
          Ember.set(this, 'open', false);
        });
      }
    },
    // Actions
    actions: {
      closeModal() {
        this.set('open', false);

        // Após fechar, verifica se existe alguma action externa para executar.
        if (this.onCloseModal) {
          this.onCloseModal();
        }
      }
    }
  });
  _exports.default = _default;
});