define("ui-nela/components/ui-radio/input", ["exports", "ui-nela/templates/components/ui-radio/input"], function (_exports, _input) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _input.default,
    tagName: 'input',
    type: 'radio',
    // option - required
    // selected - required

    attributeBindings: ['autofocus', 'checked', 'disabled', 'name', 'required', 'type', 'tabindex', 'option:value', 'ariaLabelledby:aria-labelledby', 'ariaDescribedby:aria-describedby', 'checkedStr:aria-checked'],
    checked: Ember.computed('selected', 'option', function () {
      return Ember.isEqual(this.selected, this.option);
    }),
    checkedStr: Ember.computed('checked', function () {
      return typeof this.checked === 'boolean' ? this.checked.toString() : null;
    }),
    change() {
      if (this.selected !== this.option) {
        Ember.set(this, 'selected', this.option);
        this.send('changeAction', this.option);
      }
    },
    actions: {
      changeAction(option) {
        if (this.onChange) {
          this.onChange(option);
        }
      }
    }
  });
});