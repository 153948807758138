define("ui-nela/components/ui-modal/title", ["exports", "ui-nela/templates/components/ui-modal/title"], function (_exports, _title) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    layout: _title.default,
    classNames: 'modal-header',
    actions: {
      closeModal() {
        if (this.closeModal) {
          this.closeModal();
        }
      }
    }
  });
  _exports.default = _default;
});