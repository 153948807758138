define("ui-nela/components/ui-avatar", ["exports", "ui-nela/templates/components/ui-avatar"], function (_exports, _uiAvatar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    layout: _uiAvatar.default,
    tagName: 'img',
    classNames: ['ui-avatar'],
    attributeBindings: ['source:src'],
    classNameBindings: ['isLoading:ui-avatar--loading'],
    isLoading: Ember.computed.equal('status', 'loading'),
    // Altera o src para uma imagem padrão até que a mesma seja carregada.
    source: Ember.computed('isGroup', 'src', 'status', function () {
      let defaultImg = this.isGroup ? 'group-default' : 'user-default';
      if (this.status === 'loaded') {
        return this.src;
      }
      return `/ui-nela/images/${defaultImg}.png`;
    }),
    didReceiveAttrs() {
      this._super(...arguments);

      // Armazena um backup do src original.
      if (!this.src) {
        return;
      }
      let imgSrc = this.src.toString();

      // Inicia o efeito de carregamento da imagem.
      Ember.set(this, 'status', 'loading');

      // Cria um elemento img com o src recebido pelo componente.
      let img = new Image();
      img.src = imgSrc;

      // Se a imagem carregar, remove o efeito de loading e altera o src com o backup.
      img.onload = () => {
        Ember.set(this, 'status', 'loaded');
      };

      // Caso a imagem não consiga carregar, remove efeito de loading e mantém a imagem padrão.
      img.onerror = () => {
        Ember.set(this, 'status', 'error');
      };

      // Armazena a imagem para cancelar seus eventos quando o componente for removido da tela.
      Ember.set(this, 'img', img);
    },
    willDestroyElement() {
      this._super(...arguments);
      if (this.img) {
        this.img.onload = null;
        this.img.onerror = null;
      }
    }
  });
  _exports.default = _default;
});