define("ui-nela/components/ui-navbar/nav/item", ["exports", "ui-nela/templates/components/ui-navbar/nav/item"], function (_exports, _item) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    layout: _item.default,
    tagName: 'li',
    classNames: ['ui-navbar__nav__item', 'nav-item', 'dropdown']
  });
  _exports.default = _default;
});