define("ember-power-select/templates/components/power-select/no-matches-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "+y7bP9Gi",
    "block": "{\"symbols\":[\"@noMatchesMessage\"],\"statements\":[[10,\"ul\"],[14,0,\"ember-power-select-options\"],[14,\"role\",\"listbox\"],[12],[2,\"\\n  \"],[10,\"li\"],[14,0,\"ember-power-select-option ember-power-select-option--no-matches-message\"],[14,\"role\",\"option\"],[12],[2,\"\\n    \"],[1,[32,1]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ember-power-select/templates/components/power-select/no-matches-message.hbs"
    }
  });
});