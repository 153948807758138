define("nela-mask/components/nela-input-percentage", ["exports", "nela-mask/templates/components/nela-input-mask", "vanilla-masker"], function (_exports, _nelaInputMask, _vanillaMasker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.TextField.extend({
    layout: _nelaInputMask.default,
    autocomplete: 'off',
    percentOptions: null,
    hasInput: null,
    // Life Cycle
    didInsertElement() {
      this._super(...arguments);
      let {
        element
      } = this;
      Ember.set(this, 'percentOptions', {
        precision: this.precision || 2,
        separator: this.separator || ',',
        delimiter: this.delimiter || '.',
        unit: '',
        suffixUnit: '%',
        zeroCents: this.zeroCents ? this.zeroCents : false
      });
      (0, _vanillaMasker.default)(element).maskMoney(this.percentOptions);
      element.addEventListener('change', this.changeHandler.bind(this), false);
      element.addEventListener('input', this.inputHandler.bind(this), false);
    },
    didUpdateAttrs() {
      this._super(...arguments);
      Ember.set(this, 'percentOptions', {
        precision: this.precision || 2,
        separator: this.separator || ',',
        delimiter: this.delimiter || '.',
        unit: '',
        suffixUnit: '%',
        zeroCents: this.zeroCents ? this.zeroCents : false
      });
      let {
        element
      } = this;
      (0, _vanillaMasker.default)(element).maskMoney(this.percentOptions);
    },
    didRender() {
      this._super(...arguments);
      if (this.unmasked && !this.hasInput) {
        this.element.value = _vanillaMasker.default.toMoney((Number(this.unmasked) * 100).toFixed(2), this.percentOptions);
      }
    },
    changeHandler(event) {
      const value = event.target.value;
      const separator = this.separator || ',';
      const regex = new RegExp(`[^0-9${separator}]+`, 'gm');
      let unmasked;
      if (value) {
        unmasked = value.replace(regex, '').replace(separator, '.');
        unmasked = Number(unmasked) / 100;
      }
      Ember.set(this, 'unmasked', unmasked);
      if (this.onChange) {
        this.onChange(value);
      }
      if (this.onUnmaskedChange) {
        this.onUnmaskedChange(unmasked);
      }
    },
    inputHandler() {
      Ember.set(this, 'hasInput', true);
    }
  });
});