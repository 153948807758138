define("ember-power-select/components/power-select/before-options", ["exports", "@ember-decorators/component", "ember-power-select/templates/components/power-select/before-options"], function (_exports, _component, _beforeOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _class2;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let BeforeOptions = _exports.default = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_beforeOptions.default), _dec3 = Ember._action, _dec4 = Ember._action, _dec(_class = _dec2(_class = (_class2 = class BeforeOptions extends Ember.Component {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "autofocus", true);
    }
    willDestroyElement() {
      this._super(...arguments);
      if (this.searchEnabled) {
        Ember.run.scheduleOnce('actions', this, this.select.actions.search, '');
      }
    }
    handleKeydown(e) {
      if (this.onKeydown(e) === false) {
        return false;
      }
      if (e.keyCode === 13) {
        this.select.actions.close(e);
      }
    }
    focusInput(el) {
      Ember.run.later(() => {
        if (this.autofocus) {
          el.focus();
        }
      }, 0);
    }
  }, _applyDecoratedDescriptor(_class2.prototype, "handleKeydown", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "handleKeydown"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "focusInput", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "focusInput"), _class2.prototype), _class2)) || _class) || _class);
});