define("ui-nela/templates/components/ui-navbar/nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "/JLdtn6w",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"button\"],[14,0,\"navbar-toggler\"],[14,\"data-toggle\",\"collapse\"],[14,\"data-target\",\"#uiNavbar\"],[14,\"aria-controls\",\"uiNavbar\"],[14,\"aria-expanded\",\"false\"],[14,4,\"button\"],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"navbar-toggler-icon\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"ui-navbar__collapse collapse navbar-collapse flex-row-reverse\"],[14,1,\"uiNavbar\"],[12],[2,\"\\n  \"],[10,\"ul\"],[14,0,\"ui-navbar__nav navbar-nav align-items-center\"],[12],[2,\"\\n    \"],[18,1,[[30,[36,1],null,[[\"item\",\"modules\",\"user\"],[[30,[36,0],[\"ui-navbar/nav/item\"],null],[30,[36,0],[\"ui-navbar/nav/modules\"],null],[30,[36,0],[\"ui-navbar/nav/user\"],null]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[18,1,[[30,[36,1],null,[[\"search\"],[[30,[36,0],[\"ui-navbar/nav/search\"],null]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-navbar/nav.hbs"
    }
  });
});