define("nela-report/components/nela-report", ["exports", "nela-report/templates/components/nela-report", "ember-concurrency", "jquery"], function (_exports, _nelaReport, _emberConcurrency, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    layout: _nelaReport.default,
    classNames: ['nela-report'],
    // Services
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    report: Ember.inject.service(),
    // Methods
    onError() {},
    // Tasks
    generate: (0, _emberConcurrency.task)(function* () {
      try {
        if (this.validateFilters && !this.validateFilters()) {
          return;
        }

        // eslint-disable-next-line ember/no-jquery
        let filters = _jquery.default.param(this.buildFilters() || {});
        let pdf = yield this.report.pdf(`${this.endpoint}/?${filters}`);
        if (!pdf) {
          this.toast.info('Nenhum registro encontrado.');
        }
        Ember.set(this, 'pdf', pdf);
      } catch (e) {
        this.swal.catchMessage(e);
        this.onError(e);
      }
    }).restartable()
  });
  _exports.default = _default;
});