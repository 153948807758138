define("ember-file-upload/system/file-reader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  /**
    Provides a promise-aware interface for reading files.
  
    ```js
    import File from 'ember-file-upload/file';
    import FileReader from 'ember-file-upload/system/file-reader';
  
    let reader = new FileReader();
    let file = File.fromDataURL('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAAXNSR0IArs4c6QAAACNJREFUCB1jYICC6dOn/4exwTRMAEYzwBnoOmASMBpuDLIAAIVVFiE0cg0oAAAAAElFTkSuQmCC');
  
    reader.readAsDataURL(file.blob).then((url) => {
      return url;
    }, function (err) {
      console.error(err);
    });
    ```
  
    Promises can be aborted, which will cancel the file upload:
  
    ```js
    let promise = reader.readAsDataURL(file.blob);
    promise.then((url) => {
      return url;
    }, (err) => {
      console.error(err);
    });
  
    promise.abort().then(() => {
      console.error('cancelled reading file');
    });
    ```
  
    @class FileReader
    @constructor
    @param [options] An object with a label to use to mark the promise.
   */
  function _default() {
    let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    let {
      resolve,
      reject,
      promise
    } = Ember.RSVP.defer(`ember-file-upload: ${options.label}`);
    let reader = new FileReader();
    reader.onload = resolve;
    reader.onerror = reject;
    let aborted;
    let cancel = function () {
      if (aborted == null) {
        aborted = Ember.RSVP.defer(`ember-file-upload: Abort ${options.label}`);
        reader.abort();
      }
      return aborted.promise;
    };
    reader.onabort = function () {
      aborted.resolve();
    };

    /**
      Reads the file and returns a promise that will
      return the blob as ArrayBuffer.
       @method readAsArrayBuffer
      @return {Promise} A promise that will return the file as an ArrayBuffer
     */

    /**
      Reads the file and returns a promise that will
      return the blob as data URL.
       This is useful for reading images to display
      as a preview in the browser.
       @method readAsDataURL
      @return {Promise} A promise that will return the file as a data URL
     */

    /**
      Reads the file and returns a promise that will
      return the blob as binary string.
       This is useful for reading images or files that
      are not plain text.
       @method readAsBinaryString
      @return {Promise} A promise that will return the file as a binary string
     */

    /**
      Reads the file and returns a promise that will
      return the blob as text.
       This is useful for reading plain text files.
       @method readAsText
      @return {Promise} A promise that will return the file as text
     */
    ['readAsArrayBuffer', 'readAsDataURL', 'readAsBinaryString', 'readAsText'].forEach(methodName => {
      this[methodName] = function (blob) {
        reader[methodName](blob);
        let p = promise.then(function () {
          return reader.result;
        }, function () {
          return Ember.RSVP.reject(reader.error);
        }, `ember-file-upload: Unpack ${options.label}`);
        p.cancel = cancel;
        return p;
      };
    });
  }
});