define("ui-nela/templates/components/ui-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "NpCBPEjs",
    "block": "{\"symbols\":[\"@onSubmit\",\"@validations\",\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[18,3,[[30,[36,1],null,[[\"label\",\"group\",\"input\",\"checkbox\",\"radio\",\"submit\"],[[30,[36,0],[\"ui-form/label\"],null],[30,[36,0],[\"ui-form/group\"],[[\"validations\"],[[32,2]]]],[30,[36,0],[\"ui-form/input\"],null],[30,[36,0],[\"ui-form/checkbox\"],null],[30,[36,0],[\"ui-form/radio-group\"],null],[30,[36,0],[\"ui-button\"],[[\"click\"],[[32,1]]]]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-form.hbs"
    }
  });
});