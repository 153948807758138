define("nela-datepicker/components/nela-input-time", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let NelaInputTimeComponent = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class NelaInputTimeComponent extends Ember.TextField {
    constructor() {
      super(...arguments);
      _defineProperty(this, "autocomplete", 'off');
      _defineProperty(this, "classNames", ['form-control']);
      _defineProperty(this, "allowedUnits", ['w', 'd', 'h', 'm']);
      _defineProperty(this, "minutes", 0);
      _defineProperty(this, "unitNames", {
        w: 'semana',
        d: 'dias',
        h: 'horas',
        m: 'minutos'
      });
      _initializerDefineProperty(this, "value", _descriptor, this);
    }
    // Life Cycle
    didReceiveAttrs() {
      if (!this.value) {
        this.initTime();
      }
    }

    /*
      setTime
      Ao inicializar o input, transforma os minutos para o formato "1w 2d 4h 30m"
    */
    initTime() {
      let {
        minutes,
        allowedUnits
      } = this;
      let timeStr = '';
      if (allowedUnits.includes('w')) {
        let w = parseInt(minutes / 10080);
        timeStr += w ? ` ${w}w` : '';
        minutes = minutes % 10080;
      }
      if (allowedUnits.includes('d')) {
        let d = parseInt(minutes / 1440);
        timeStr += d ? ` ${d}d` : '';
        minutes = minutes % 1440;
      }
      if (allowedUnits.includes('h')) {
        let h = parseInt(minutes / 60);
        timeStr += h ? ` ${h}h` : '';
        minutes = minutes % 60;
      }
      let m = parseInt(minutes);
      timeStr += m ? ` ${m}m` : '';
      this.value = timeStr.trim();
    }

    /*
      keyUp
      Ao digitar no input, transforma o valor no formato "1w 2d 4h 30m" para minutos
    */
    keyUp() {
      let {
        value,
        allowedUnits,
        element
      } = this;
      let now = (0, _moment.default)();
      let date = now.clone();
      let splited = value.split(' ');
      let inputError;
      splited.forEach(split => {
        let number = split.replace(/\D/g, '');
        let unit = split.replace(/\d/g, '');
        let splitError = !number || !unit || !allowedUnits.includes(unit);
        if (!splitError) {
          date.add(number, unit);
        }
        inputError = splitError || inputError;
      });
      if (inputError) {
        element.classList.add('border-danger');
        element.title = 'Formato incorreto';
      } else {
        element.classList.remove('border-danger');
        element.title = '';
      }
      let diff = date.diff(now, 'minute');
      Ember.set(this, 'minutes', diff);
      if (this.onChange) {
        this.onChange(diff);
      }
    }

    /*
      click
      Aciona popover com instruções de utilização do input
    */
    click() {
      let {
        allowedUnits,
        unitNames
      } = this;
      let content = allowedUnits.map(unit => `${unit} = ${unitNames[unit]}`).join('<br>');
      $(this.element).popover({
        content,
        html: true,
        placement: 'bottom',
        template: '<div class="d-none d-lg-block popover bg-primary" role="tooltip"><div class="arrow"></div><strong class="popover-body d-block text-white"></strong></div>',
        trigger: 'focus'
      }).popover('show');
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "value", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "keyUp", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "keyUp"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "click", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "click"), _class.prototype)), _class));
  _exports.default = NelaInputTimeComponent;
});