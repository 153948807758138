define("ui-nela/components/ui-navbar/brand", ["exports", "ui-nela/templates/components/ui-navbar/brand"], function (_exports, _brand) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.LinkComponent.extend({
    layout: _brand.default,
    classNames: ['ui-navbar__brand', 'navbar-brand']
  });
});