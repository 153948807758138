define("ui-nela/templates/components/ui-navbar/nav/user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "/JCIKv8L",
    "block": "{\"symbols\":[\"@user\",\"&default\"],\"statements\":[[10,\"button\"],[14,0,\"btn text-white border-0 p-0\"],[14,\"data-toggle\",\"dropdown\"],[14,\"aria-haspopup\",\"true\"],[14,\"aria-expanded\",\"false\"],[12],[2,\"\\n  \"],[8,\"ui-avatar\",[[24,0,\"avatar-4\"],[16,\"alt\",[32,1,[\"fullName\"]]]],[[\"@src\"],[[32,1,[\"avatar\"]]]],null],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"ui-navbar__nav__user__name\"],[12],[2,\"\\n    \"],[1,[32,1,[\"firstName\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[18,2,[[30,[36,1],null,[[\"dropdown\"],[[30,[36,0],[\"ui-navbar/nav/dropdown\"],[[\"class\"],[\"dropdown-menu-right\"]]]]]]]]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-navbar/nav/user.hbs"
    }
  });
  _exports.default = _default;
});