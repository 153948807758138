define("nela-datatable/components/nela-datatable-buttons", ["exports", "@glimmer/component", "ember-concurrency-decorators", "moment"], function (_exports, _component, _emberConcurrencyDecorators, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @exportFields}}
    <div class="float-right mr-2 mt-1">
      <button type="button" class="btn btn-sm nela-datatable-btn-flat" onclick={{perform exportToCsv}} title="Exportar CSV">
        <i class="fa fa-file-csv" aria-hidden="true"></i>
        <span class="nela-datatable-export-label">Exportar CSV</span>
      </button>
    </div>
  {{/if}}
  
  */
  {
    "id": "d4vl6Dya",
    "block": "{\"symbols\":[\"@exportFields\"],\"statements\":[[6,[37,2],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"float-right mr-2 mt-1\"],[12],[2,\"\\n    \"],[10,\"button\"],[14,0,\"btn btn-sm nela-datatable-btn-flat\"],[15,\"onclick\",[30,[36,1],[[35,0]],null]],[14,\"title\",\"Exportar CSV\"],[14,4,\"button\"],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-file-csv\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n      \"],[10,\"span\"],[14,0,\"nela-datatable-export-label\"],[12],[2,\"Exportar CSV\"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"exportToCsv\",\"perform\",\"if\"]}",
    "meta": {
      "moduleName": "nela-datatable/components/nela-datatable-buttons.hbs"
    }
  });
  let NelaDatatableButtonsComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = class NelaDatatableButtonsComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "fetch", _descriptor, this);
      _initializerDefineProperty(this, "swal", _descriptor2, this);
      _initializerDefineProperty(this, "session", _descriptor3, this);
    }
    *exportToCsv() {
      this.swal.loading('Carregando...');
      let records = yield this.loadData.perform();
      if (this.args.exportObjectName && records) {
        records = records[this.args.exportObjectName];
      }
      if (this.args.exportFields && records) {
        try {
          if (!records.length) {
            return this.swal.info('Nenhum valor encontrado');
          }
          let csvContent = "data:text/csv;charset=utf-8,";
          this.args.exportFields.forEach(field => {
            csvContent += `${field.fieldName ? field.fieldName : field.field};`;
          });
          csvContent += '\n';
          records.forEach(el => {
            let csvLine = '';
            this.args.exportFields.forEach(field => {
              if (field.type === 'object') {
                let record = el;
                const array = field.field.split('.');
                array.forEach(a => {
                  record = record[a];
                });
                csvLine += `${record && record != 'null' ? record : ''};`;
              } else if (field.type === 'date') {
                csvLine += `${el[field.field] && el[field.field] != 'null' ? (0, _moment.default)(el[field.field]).format(field.dateFormat) : ''};`;
              } else {
                csvLine += `${el[field.field] && el[field.field] != 'null' ? el[field.field] : ''};`;
              }
            });
            csvLine = csvLine.replaceAll(/(\r\n|\n|\r)/gm, " ");
            const includeRegex = /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/ig;
            const excludeRegex = /.*@.*/;
            csvLine = csvLine.replaceAll(includeRegex, line => {
              if (excludeRegex.test(line)) {
                return line;
              } else {
                return " [Link não suportado para arquivos CSV] ";
              }
            });
            csvLine += '\n';
            csvContent += csvLine;
          });
          let encodedUri = encodeURI(csvContent);
          let link = document.createElement('a');
          let exportFileName = `${this.args.exportFileName ? this.args.exportFileName : 'export'}.csv`;
          link.setAttribute('href', encodedUri);
          link.setAttribute('download', exportFileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.swal.close();
        } catch (e) {
          console.log(e);
          this.swal.catch(e);
        }
      }
    }
    *loadData() {
      let data = this.args.filters || {};
      let endpoint = this.args.endPoint;
      let host = this.args.host;
      let options = {
        data
      };
      let result = null;
      delete options.data.limit;
      if (host) {
        options.host = host;
      }
      if (endpoint) {
        result = yield this.fetch.request(endpoint, options);
      }
      result = result ? result.data ? result.data : result : Ember.A();
      return result;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "exportToCsv", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "exportToCsv"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadData", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadData"), _class.prototype)), _class));
  _exports.default = NelaDatatableButtonsComponent;
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, NelaDatatableButtonsComponent);
});