define("nela-fetch/services/fetch", ["exports", "ember-concurrency-decorators", "fetch", "@ember-data/adapter/error", "ember-fetch/errors"], function (_exports, _emberConcurrencyDecorators, _fetch, _error, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let FetchService = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _class = class FetchService extends Ember.Service {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "session", _descriptor, this);
      _initializerDefineProperty(this, "toast", _descriptor2, this);
    }
    del(url = '', options = {}) {
      options.method = 'DELETE';
      return this.fetchRequest.perform(url, options);
    }
    file(url = '', options = {}) {
      return this.fileRequest.perform(url, options);
    }
    pdf(url = '', options = {}) {
      return this.fileRequest.perform(url, options);
    }
    post(url = '', options = {}) {
      options.method = 'POST';
      return this.fetchRequest.perform(url, options);
    }
    put(url = '', options = {}) {
      options.method = 'PUT';
      return this.fetchRequest.perform(url, options);
    }
    request(url = '', options = {}) {
      return this.fetchRequest.perform(url, options);
    }
    serialize(obj, attr) {
      let str = [];
      for (let key in obj) {
        if (obj[key] === undefined) {
          continue;
        }
        let param;
        let prefix = attr ? `${attr}[${key}]` : key;
        if (Ember.isArray(obj[key])) {
          param = obj[key].map((value, index) => {
            if (typeof value === 'object' && value !== null) {
              return this.serialize(value, `${prefix}[${index}]`);
            }
            return `${encodeURIComponent(`${prefix}[]`)}=${encodeURIComponent(value)}`;
          });
          param = param.join('&');
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
          param = this.serialize(obj[key], prefix);
        } else {
          let value = obj[key] === null ? '' : obj[key];
          param = `${encodeURIComponent(prefix)}=${encodeURIComponent(value)}`;
        }
        str.pushObject(param);
      }
      return str.join('&');
    }
    *fetch(url, options) {
      let method = options.method || 'GET';
      let headers = this.session.authorizationBearer;
      let environment = Ember.getOwner(this).factoryFor('config:environment').class;
      let host = this.host || environment.apiUrl.core;
      if (options.host) {
        if (/^http(s)*:/.test(options.host)) {
          host = options.host;
        } else {
          host = environment.apiUrl[options.host];
        }
      }
      if (host === '/api') {
        host = `${window.location.origin}/api`;
      }
      url = url.replace(/^\//g, '');
      url = `${host}/${url}`;
      let data = options.data || {};
      if (method === 'GET') {
        let query = this.serialize(data);
        if (query) {
          url += `?${query}`;
        }
      } else {
        options.body = JSON.stringify(data);
      }
      options = {
        headers,
        method,
        ...options
      };
      try {
        let response = yield (0, _fetch.default)(url, options);
        if (response.ok) {
          return response;
        }
        let errors = [{
          detail: yield response.text(),
          status: response.status,
          title: 'The backend responded with an error'
        }];
        if ((0, _errors.isUnauthorizedResponse)(response)) {
          return this.session.invalidate();
        }
        if ((0, _errors.isForbiddenResponse)(response)) {
          throw new _error.ForbiddenError(errors);
        }
        if ((0, _errors.isNotFoundResponse)(response)) {
          throw new _error.NotFoundError(errors);
        }
        if ((0, _errors.isServerErrorResponse)(response)) {
          throw new _error.ServerError([{
            detail: 'Ocorreu um erro inesperado, tente novamente mais tarde',
            status: response.status,
            title: 'The backend responded with an error'
          }]);
        }
        throw new _error.default(errors);
      } catch (e) {
        if (e.message === 'Network request failed') {
          throw 'Falha na conexão';
        }
        throw e;
      }
    }
    *fetchRequest() {
      let response = yield this.fetch.perform(...arguments);
      if (response.status === 204) {
        return;
      }
      let content = yield response.json();
      return content;
    }
    *fileRequest() {
      let response = yield this.fetch.perform(...arguments);
      let file = yield response.blob();
      if (file.size) {
        return URL.createObjectURL(file);
      }
      this.toast.info('Nenhum registro encontrado');
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fetch", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetch"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchRequest", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchRequest"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fileRequest", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fileRequest"), _class.prototype), _class);
});