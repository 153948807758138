define("nela-dataloader/components/nela-paging/btn-load-more", ["exports", "nela-dataloader/templates/components/nela-paging/btn-load-more"], function (_exports, _btnLoadMore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _btnLoadMore.default,
    tagName: 'button',
    classNames: ['nela-paging__btn-load-more', 'btn', 'btn-link'],
    classNameBindings: ['hideLoadMore:d-none']
  });
});