define("nela-datepicker/components/nela-datepicker", ["exports", "nela-datepicker/templates/components/nela-datepicker", "ember-power-calendar-utils", "ember-concurrency", "vanilla-masker", "moment"], function (_exports, _nelaDatepicker, _emberPowerCalendarUtils, _emberConcurrency, _vanillaMasker, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const KEY_EVENTS = ['Backspace', 'Delete', 'Home', 'End', 'Tab', 'ArrowLeft', 'ArrowRight', '/', 'F1', 'F2', 'F3', 'F4', 'F5', 'F6', 'F7', 'F8', 'F9', 'F10', 'F11', 'F12'];
  const WEEK_DAYS = ['Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab', 'Dom'];
  var _default = _exports.default = Ember.Component.extend({
    // HTML attributes
    layout: _nelaDatepicker.default,
    classNames: ['nela-datepicker'],
    // Default values
    allowClear: false,
    closeOnSelect: true,
    format: 'DD/MM/YYYY',
    horizontalPosition: 'auto',
    placeholder: 'Selecionar data',
    renderInPlace: true,
    verticalPosition: 'auto',
    weekdayFormat: 'short',
    // "min" | "short" | "long"

    // Multiline computed properties
    showClearButton: Ember.computed('allowClear', 'disabled', function () {
      return this.allowClear && !this.disabled;
    }),
    // Lifecycle hooks
    didReceiveAttrs() {
      this._super(...arguments);
      if (this.selected && typeof this.selected === 'string') {
        Ember.set(this, 'selected', (0, _moment.default)(this.selected, this.format).toDate());
      }
    },
    // Tasks
    handleKeyUp: (0, _emberConcurrency.task)(function* (value, e) {
      yield (0, _emberConcurrency.timeout)(1000);
      e.target.value = _vanillaMasker.default.toPattern(e.target.value, "99/99/9999");
    }).restartable(),
    // Custom methods
    buildDay(date, calendar) {
      date.set({
        hours: 0,
        minutes: 0,
        seconds: 0
      });
      return (0, _emberPowerCalendarUtils.normalizeCalendarDay)({
        id: date.format('YYYY-MM-DD'),
        date: date.toDate(),
        isCurrentMonth: date.month() === (0, _moment.default)(calendar.center).month(),
        isDisabled: this.dayIsDisabled(date, calendar),
        isSelected: this.dayIsSelected(date, calendar),
        isToday: (0, _moment.default)().isSame(date, 'day'),
        number: date.date()
      });
    },
    dayIsDisabled(date, calendar) {
      let isDisabled = !calendar.actions.select;
      if (isDisabled) {
        return true;
      }
      if (this.minDate && date.isBefore((0, _moment.default)(this.minDate).startOf('day'))) {
        return true;
      }
      if (this.maxDate && date.isAfter((0, _moment.default)(this.maxDate).endOf('day'))) {
        return true;
      }
      if (this.disabledDates) {
        let disabledInRange = this.disabledDates.some(d => {
          let isSameDay = date.isSame(d, 'day');
          let isWeekDayIncludes = WEEK_DAYS.indexOf(d) !== -1 && date.format('ddd') === d;
          return isSameDay || isWeekDayIncludes;
        });
        if (disabledInRange) {
          return true;
        }
      }
      return false;
    },
    dayIsSelected(date, calendar) {
      return calendar.selected ? date.isSame(calendar.selected, 'day') : false;
    },
    // Actions
    actions: {
      handleClear() {
        let selected = this.selected;
        Ember.set(this, 'selected', undefined);
        if (this.onClear) {
          this.onClear(selected, ...arguments);
        }
      },
      handleFocusOut(dropdown, calendar, value, e) {
        if (!value) {
          return this.send('handleClear');
        }
        let content = e.relatedTarget;
        if (content && content.closest('.ember-basic-dropdown-content')) {
          return;
        }
        let day = (0, _moment.default)(value, this.format);
        if (!day.isValid()) {
          e.target.value = null;
          return Ember.set(this, 'selected', null);
        }
        day = this.buildDay(day, calendar);
        this.send('handleSelect', dropdown, day, calendar, e);
      },
      handleKeyDown(dropdown, calendar, value = '', e) {
        let key = parseInt(e.key);
        let preventDefault = false;
        let specialKeys = e.altKey || e.ctrlKey || e.shiftKey;
        let char = value.match(/\//g);
        if (!char) {
          preventDefault = value.length > 7;
        } else if (char.length === 1) {
          preventDefault = value.length > 8;
        } else if (char.length === 2) {
          preventDefault = value.length > 9;
        }
        if (e.target.selectionStart !== e.target.selectionEnd) {
          preventDefault = false;
        }
        if (e.key === 'Enter') {
          if (!value) {
            return;
          }
          let day = (0, _moment.default)(value, this.format);
          if (!day.isValid()) {
            e.target.value = null;
            return Ember.set(this, 'selected', null);
          }
          let trigger = this.element.querySelector(`[data-ebd-id=${dropdown.uniqueId}-trigger]`);
          if (trigger) {
            trigger.blur();
          }
        }
        if ((isNaN(key) || preventDefault) && !KEY_EVENTS.includes(e.key) && !specialKeys) {
          e.preventDefault();
        }
      },
      handleSelect(dropdown, day, calendar, e) {
        if (this.onSelect) {
          this.onSelect(day, calendar, e);
        } else {
          Ember.set(this, 'selected', day.date);
        }
        Ember.set(this, 'center', day.date);
        if (this.afterSelect) {
          this.afterSelect(day, calendar, e);
        }
        if (!this.closeOnSelect) {
          return;
        }
        dropdown.actions.close(e, true);
      },
      selectToday(dropdown, calendar, e) {
        let today = (0, _moment.default)();
        let day = this.buildDay(today, calendar);
        this.send('handleSelect', dropdown, day, calendar, e);
      }
    }
  });
});