define("ui-nela/components/ui-skeleton/text/line", ["exports", "ui-nela/templates/components/ui-skeleton/text/line"], function (_exports, _line) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _line.default,
    classNames: ['ui-skeleton__text__line', 'ui-skeleton__animated'],
    classNameBindings: ['classSize'],
    classSize: Ember.computed('size', function () {
      if (this.size) {
        return `ui-skeleton__text__line--${this.size}`;
      } else {
        return '';
      }
    })
  });
});