define("ui-nela/templates/components/ui-modal/title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "A47eD365",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"h5\"],[14,0,\"modal-title\"],[12],[18,1,null],[13],[2,\"\\n\\n\"],[10,\"button\"],[14,0,\"close\"],[15,\"onclick\",[30,[36,0],[[32,0],\"closeModal\"],null]],[14,4,\"button\"],[12],[2,\"\\n  \"],[10,\"i\"],[14,0,\"fa fa-times\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-modal/title.hbs"
    }
  });
});