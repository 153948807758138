define("ui-nela/templates/components/ui-radio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "jUJPbnHo",
    "block": "{\"symbols\":[\"@radioId\",\"@option\",\"@selected\",\"@name\",\"@autofocus\",\"@disabled\",\"@required\",\"@tabindex\",\"@aria-labelledby\",\"@aria-describedby\",\"&default\"],\"statements\":[[10,\"label\"],[14,0,\"ui-radio__label\"],[15,\"for\",[30,[36,1],[[32,1],[30,[36,0],[\"ui-radio-\",[32,0,[\"elementId\"]]],null]],null]],[12],[2,\"\\n  \"],[8,\"ui-radio/input\",[[16,1,[30,[36,1],[[32,1],[30,[36,0],[\"ui-radio-\",[32,0,[\"elementId\"]]],null]],null]],[16,\"tabindex\",[32,8]],[16,\"aria-labelledby\",[32,9]],[16,\"aria-describedby\",[32,10]]],[[\"@option\",\"@selected\",\"@onChange\",\"@name\",\"@autofocus\",\"@disabled\",\"@required\"],[[32,2],[32,3],[30,[36,2],[[32,0],\"onChange\"],null],[32,4],[32,5],[32,6],[32,7]]],null],[2,\"\\n\\n  \"],[10,\"span\"],[14,0,\"ui-radio__circle\"],[12],[13],[2,\"\\n  \"],[10,\"span\"],[14,0,\"ui-radio__check\"],[12],[13],[2,\"\\n  \"],[18,11,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"concat\",\"or\",\"action\"]}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-radio.hbs"
    }
  });
  _exports.default = _default;
});