define("nela-datatable/components/nela-datatable-th", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <th class="nela-datatable-th cursor-pointer {{@class}}" onclick={{fn click}} {{did-insert (fn onInsertField)}}>
    {{yield}}
  
    {{#if @field}}
      {{#if isAscending}}
        <img
          class="nela-datatable-sort"
          src="https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grfjqezlbdq4/b/nela-cdn/o/voors%2Ficons%2Farrow-down.svg"
          width="14"
        >
      {{/if}}
  
      {{#if isDescending}}
        <img
          class="nela-datatable-sort"
          src="https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grfjqezlbdq4/b/nela-cdn/o/voors%2Ficons%2Farrow-up.svg"
          width="14"
        >
      {{/if}}
  
      {{#if (and (not isDescending) (not isAscending))}}
        <img
          class="nela-datatable-sort"
          src="https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grfjqezlbdq4/b/nela-cdn/o/indicators/icons/arrow-direction.svg"
          width="12"
        >
      {{/if}}  
    {{/if}}
  </th>
  
  */
  {
    "id": "G0YXOkiG",
    "block": "{\"symbols\":[\"@class\",\"&default\",\"@field\"],\"statements\":[[11,\"th\"],[16,0,[31,[\"nela-datatable-th cursor-pointer \",[32,1]]]],[16,\"onclick\",[30,[36,6],[[35,5]],null]],[4,[38,8],[[30,[36,6],[[35,7]],null]],null],[12],[2,\"\\n  \"],[18,2,null],[2,\"\\n\\n\"],[6,[37,1],[[32,3]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"img\"],[14,0,\"nela-datatable-sort\"],[14,\"src\",\"https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grfjqezlbdq4/b/nela-cdn/o/voors%2Ficons%2Farrow-down.svg\"],[14,\"width\",\"14\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"img\"],[14,0,\"nela-datatable-sort\"],[14,\"src\",\"https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grfjqezlbdq4/b/nela-cdn/o/voors%2Ficons%2Farrow-up.svg\"],[14,\"width\",\"14\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],[[30,[36,4],[[30,[36,3],[[35,2]],null],[30,[36,3],[[35,0]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"img\"],[14,0,\"nela-datatable-sort\"],[14,\"src\",\"https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grfjqezlbdq4/b/nela-cdn/o/indicators/icons/arrow-direction.svg\"],[14,\"width\",\"12\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"isAscending\",\"if\",\"isDescending\",\"not\",\"and\",\"click\",\"fn\",\"onInsertField\",\"did-insert\"]}",
    "meta": {
      "moduleName": "nela-datatable/components/nela-datatable-th.hbs"
    }
  });
  let NelaDatatableThComponent = (_dec = Ember.computed('args.field', 'args.sort.{field,order}', 'field', 'sort'), _dec2 = Ember.computed('args.field', 'args.sort.{field,order}', 'field', 'sort'), _dec3 = Ember._action, _dec4 = Ember._action, (_class = class NelaDatatableThComponent extends _component.default {
    get isAscending() {
      let sort = this.args.sort;
      let field = this.args.field;
      if (sort) {
        return sort.field === field && sort.order === 'ASC';
      }
      return false;
    }
    get isDescending() {
      let sort = this.args.sort;
      let field = this.args.field;
      if (sort) {
        return sort.field === field && sort.order === 'DESC';
      }
      return false;
    }
    click() {
      let onClick = this.args.onClick;
      let field = this.args.field;
      if (field) {
        onClick(field);
      }
    }
    onInsertField() {
      this.args.onInsertField(this.args.field, this.args.fieldName);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "isAscending", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "isAscending"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isDescending", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "isDescending"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "click", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "click"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onInsertField", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "onInsertField"), _class.prototype)), _class));
  _exports.default = NelaDatatableThComponent;
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, NelaDatatableThComponent);
});