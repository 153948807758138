define("nela-dataloader/components/nela-paging/paging-status", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @meta}}
    <div class="nela-paging__paging-status text-center small">
      {{@records.length}} de {{@meta.total}}
    </div>
  {{/if}}
  
  */
  {
    "id": "7Mwlyn97",
    "block": "{\"symbols\":[\"@records\",\"@meta\"],\"statements\":[[6,[37,0],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"nela-paging__paging-status text-center small\"],[12],[2,\"\\n    \"],[1,[32,1,[\"length\"]]],[2,\" de \"],[1,[32,2,[\"total\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "nela-dataloader/components/nela-paging/paging-status.hbs"
    }
  });
  class NelaPagingPagingStatusComponent extends _component.default {}
  _exports.default = NelaPagingPagingStatusComponent;
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, NelaPagingPagingStatusComponent);
});