define("nela-select/components/nela-select", ["exports", "nela-select/components/nela-select-basic", "nela-select/templates/components/nela-select", "ember-concurrency"], function (_exports, _nelaSelectBasic, _nelaSelect, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _nelaSelectBasic.default.extend({
    layout: _nelaSelect.default,
    classNames: ['nela-select'],
    // Methods
    onChange(selected) {
      if (this.modelName && selected) {
        this.findEmberDataRecord.perform(...arguments);
      } else {
        Ember.set(this, 'selected', selected);

        // Chama um hook customizado.
        if (this.afterChange) {
          this.afterChange(...arguments);
        }
      }
    },
    // Concurrency Tasks
    findEmberDataRecord: (0, _emberConcurrency.task)(function* (record) {
      try {
        let emberDataRecord = yield this.store.findRecord(this.modelName, record.id);
        Ember.set(this, 'selected', emberDataRecord);

        // Chama um hook customizado.
        if (this.afterChange) {
          this.afterChange(emberDataRecord, ...arguments);
        }
      } catch (e) {
        Ember.debug(e);
        if (this.onError) {
          this.onError(...arguments);
        }
      }
    }).restartable()
  });
});