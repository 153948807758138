define("ui-nela/components/ui-list/list-item", ["exports", "ui-nela/templates/components/ui-list/list-item"], function (_exports, _listItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    layout: _listItem.default,
    tagName: 'li',
    classNames: ['ui-list__list-item', 'list-group-item']
  });
  _exports.default = _default;
});