define("ui-nela/templates/components/ui-form/group/status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "jdPcE98I",
    "block": "{\"symbols\":[\"@message\"],\"statements\":[[1,[30,[36,1],[[32,1],[30,[36,0],[\"* \",[32,1]],null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"concat\",\"if\"]}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-form/group/status.hbs"
    }
  });
});