define("ui-nela/components/ui-page", ["exports", "ui-nela/templates/components/ui-page"], function (_exports, _uiPage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    layout: _uiPage.default,
    classNames: ['ui-page']
  });
  _exports.default = _default;
});