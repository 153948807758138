define("nela-report/components/nela-report/card-filter", ["exports", "nela-report/templates/components/nela-report/card-filter"], function (_exports, _cardFilter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    layout: _cardFilter.default,
    classNames: ['ui-card', 'card', 'mb-4 shadow-sm']
  });
  _exports.default = _default;
});