define("ember-simple-auth-token/mixins/token-adapter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
    Adapter Mixin that works with token-based authentication like JWT.
  
    @class TokenAdapter
    @module ember-simple-auth-token/mixins/token-adapter
    @extends Ember.Mixin
  */
  var _default = Ember.Mixin.create({
    session: Ember.inject.service('session'),
    /**
      @method init
    */
    init() {
      this._super(...arguments);
      const owner = Ember.getOwner(this);
      const environment = owner ? owner.resolveRegistration('config:environment') || {} : {};
      const config = environment['ember-simple-auth-token'] || {};
      this.tokenPropertyName = config.tokenPropertyName || 'token';
      this.authorizationHeaderName = config.authorizationHeaderName || 'Authorization';
      this.authorizationPrefix = config.authorizationPrefix === '' ? '' : config.authorizationPrefix || 'Bearer ';
    },
    /*
      Adds the `token` property from the session to the `authorizationHeaderName`.
    */
    headers: Ember.computed('session.data.authenticated', function () {
      const data = this.get('session.data.authenticated');
      const token = Ember.get(data, this.get('tokenPropertyName'));
      const prefix = this.get('authorizationPrefix');
      const header = this.get('authorizationHeaderName');
      if (this.get('session.isAuthenticated') && !Ember.isEmpty(token)) {
        return {
          [header]: `${prefix}${token}`
        };
      } else {
        return {};
      }
    }),
    /**
      Handles response from server.
       @method authorize
      @param {Number} status
    */
    handleResponse(status) {
      if (status === 401 && this.get('session.isAuthenticated')) {
        this.get('session').invalidate();
      }
      return this._super(...arguments);
    }
  });
  _exports.default = _default;
});