define("ui-nela/helpers/assign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.helper(function assign(params /*, hash*/) {
    let obj = {};
    params.forEach(param => {
      Object.assign(obj, param);
    });
    return obj;
  });
});