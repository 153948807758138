define("nela-report/templates/components/nela-report/card-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "dQTXj87x",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[8,\"ui-card/header\",[[24,0,\"text-primary\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"i\"],[14,0,\"fa fa-filter\"],[12],[13],[2,\" Filtros\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[8,\"ui-card/body\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[8,\"ui-card/footer\",[[24,0,\"text-right\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"button\"],[14,0,\"btn btn-primary btn-sm\"],[15,\"onclick\",[30,[36,1],[[35,0]],null]],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-cogs\"],[14,\"aria-hidden\",\"\"],[12],[13],[2,\"\\n    Gerar Relatório\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"generate\",\"perform\"]}",
    "meta": {
      "moduleName": "nela-report/templates/components/nela-report/card-filter.hbs"
    }
  });
  _exports.default = _default;
});