define("ui-nela/templates/components/ui-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "fPt76VpJ",
    "block": "{\"symbols\":[\"@checkboxId\",\"@value\",\"@checked\",\"@autofocus\",\"@disabled\",\"@indeterminate\",\"@name\",\"@required\",\"@tabindex\",\"@aria-labelledby\",\"@aria-describedby\",\"&default\"],\"statements\":[[8,\"ui-checkbox/input\",[[16,1,[30,[36,1],[[32,1],[30,[36,0],[[32,0,[\"elementId\"]],\"-input\"],null]],null]],[16,\"autofocus\",[32,4]],[16,\"disabled\",[32,5]],[16,\"indeterminate\",[32,6]],[16,3,[32,7]],[16,\"required\",[32,8]],[16,\"tabindex\",[32,9]],[16,\"aria-labelledby\",[32,10]],[16,\"aria-describedby\",[32,11]],[24,4,\"checkbox\"]],[[\"@value\",\"@checked\",\"@onChange\"],[[32,2],[32,3],[30,[36,2],[[32,0],\"handleChange\"],null]]],null],[2,\"\\n\\n\"],[10,\"label\"],[15,\"for\",[30,[36,1],[[32,1],[30,[36,0],[[32,0,[\"elementId\"]],\"-input\"],null]],null]],[12],[18,12,null],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"concat\",\"or\",\"action\"]}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-checkbox.hbs"
    }
  });
});