define("ui-nela/components/ui-textarea", ["exports", "ui-nela/templates/components/ui-textarea"], function (_exports, _uiTextarea) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.TextArea.extend({
    layout: _uiTextarea.default,
    classNames: ['ui-textarea'],
    autocomplete: 'off',
    didInsertElement() {
      this._super(...arguments);
      Ember.run.later(this, () => {
        if (this.element.scrollHeight) {
          this.element.setAttribute('style', `height:${this.element.scrollHeight}px;`);
        }
      });
    },
    didUpdate() {
      this.element.setAttribute('style', `height:auto;`);
      this.element.setAttribute('style', `height:${this.element.scrollHeight}px;`);
      this.element.scrollIntoView(false);
    }
  });
  _exports.default = _default;
});