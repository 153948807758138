define("ui-nela/components/ui-crud/view/section/tabs/link", ["exports", "ui-nela/templates/components/ui-crud/view/section/tabs/link"], function (_exports, _link) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.LinkComponent.extend({
    layout: _link.default,
    tagName: 'li',
    classNames: ['nav-item', 'nav-link']
  });
});