define("nela-datepicker/components/nela-input-time", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let NelaInputTimeComponent = _exports.default = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, _class = class NelaInputTimeComponent extends Ember.TextField {
    constructor(...args) {
      super(...args);
      // Html attributes
      _defineProperty(this, "autocomplete", 'off');
      _defineProperty(this, "classNames", ['form-control']);
      // Attributes
      _defineProperty(this, "allowedUnits", ['w', 'd', 'h', 'm']);
      _defineProperty(this, "minutes", 0);
      _defineProperty(this, "unitNames", {
        w: 'semana',
        d: 'dias',
        h: 'horas',
        m: 'minutos'
      });
      // Tracked attributes
      _initializerDefineProperty(this, "value", _descriptor, this);
    }
    // Life Cycle
    didReceiveAttrs() {
      if (!this.value) {
        this.initTime();
      }
    }

    /*
      setTime
      Ao inicializar o input, transforma os minutos para o formato "1w 2d 4h 30m"
    */
    initTime() {
      let {
        minutes,
        allowedUnits
      } = this;
      let timeStr = '';
      if (allowedUnits.includes('w')) {
        let w = parseInt(minutes / 10080);
        timeStr += w ? ` ${w}w` : '';
        minutes = minutes % 10080;
      }
      if (allowedUnits.includes('d')) {
        let d = parseInt(minutes / 1440);
        timeStr += d ? ` ${d}d` : '';
        minutes = minutes % 1440;
      }
      if (allowedUnits.includes('h')) {
        let h = parseInt(minutes / 60);
        timeStr += h ? ` ${h}h` : '';
        minutes = minutes % 60;
      }
      let m = parseInt(minutes);
      timeStr += m ? ` ${m}m` : '';
      this.value = timeStr.trim();
    }

    /*
      keyUp
      Ao digitar no input, transforma o valor no formato "1w 2d 4h 30m" para minutos
    */
    keyUp() {
      let {
        value,
        allowedUnits,
        element
      } = this;
      let now = (0, _moment.default)();
      let date = now.clone();
      let splited = value.split(' ');
      let inputError;
      splited.forEach(split => {
        let number = split.replace(/\D/g, '');
        let unit = split.replace(/\d/g, '');
        let splitError = !number || !unit || !allowedUnits.includes(unit);
        if (!splitError) {
          date.add(number, unit);
        }
        inputError = splitError || inputError;
      });
      if (inputError) {
        element.classList.add('border-danger');
        element.title = 'Formato incorreto';
      } else {
        element.classList.remove('border-danger');
        element.title = '';
      }
      let diff = date.diff(now, 'minute');
      Ember.set(this, 'minutes', diff);
      if (this.onChange) {
        this.onChange(diff);
      }
    }

    /*
      click
      Aciona popover com instruções de utilização do input
    */
    click() {
      let {
        allowedUnits,
        unitNames
      } = this;
      let content = allowedUnits.map(unit => `${unit} = ${unitNames[unit]}`).join('<br>');
      $(this.element).popover({
        content,
        html: true,
        placement: 'bottom',
        template: '<div class="d-none d-lg-block popover bg-primary" role="tooltip"><div class="arrow"></div><strong class="popover-body d-block text-white"></strong></div>',
        trigger: 'focus'
      }).popover('show');
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "value", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "keyUp", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "keyUp"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "click", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "click"), _class.prototype), _class);
});