define("ui-nela/components/ui-page/header", ["exports", "ui-nela/templates/components/ui-page/header"], function (_exports, _header) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    layout: _header.default,
    classNames: ['ui-page__header', 'ui-container', 'container', 'mb-2']
  });
  _exports.default = _default;
});