define("ui-nela/components/ui-crud/view", ["exports", "ui-nela/templates/components/ui-crud/view"], function (_exports, _view) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    layout: _view.default,
    classNames: ['ui-crud__view', 'col-8']
  });
  _exports.default = _default;
});