define("ui-nela/helpers/show-if", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.showIf = showIf;
  function showIf([condition] /*, hash*/) {
    return !condition ? 'd-none' : '';
  }
  var _default = _exports.default = Ember.Helper.helper(showIf);
});