define("ui-nela/templates/components/ui-crud/list/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "L2H6/Yzh",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,1],null,[[\"tabs\",\"btnNew\"],[[30,[36,0],[\"ui-crud/list/header/tabs\"],null],[30,[36,0],[\"link-to\"],[[\"class\"],[\"ui-crud__list__header__btn-new btn\"]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-crud/list/header.hbs"
    }
  });
});