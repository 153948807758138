define("ui-nela/templates/components/ui-navbar/nav/modules", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "zzVebN4E",
    "block": "{\"symbols\":[\"module\"],\"statements\":[[10,\"button\"],[14,0,\"ui-navbar__nav__btn\"],[14,\"data-toggle\",\"dropdown\"],[14,\"role\",\"button\"],[14,\"aria-haspopup\",\"true\"],[14,\"aria-expanded\",\"false\"],[12],[2,\"\\n  \"],[10,\"i\"],[14,0,\"fa fa-th\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"ui-navbar__nav__modules dropdown-menu centralize-x\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row align-items-center\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"modules\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"col-sm-4 text-center\"],[12],[2,\"\\n          \"],[10,\"a\"],[15,6,[30,[36,0],[[32,1,[\"type\"]]],null]],[14,0,\"ui-navbar__nav__modules__icon\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[2,\"\\n            \"],[10,\"img\"],[15,\"src\",[31,[\"https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grfjqezlbdq4/b/nela-cdn/o/icons/\",[32,1,[\"type\"]],\".svg\"]]],[15,\"alt\",[32,1,[\"title\"]]],[12],[13],[2,\"\\n            \"],[10,\"p\"],[14,0,\"mb-0\"],[12],[1,[32,1,[\"title\"]]],[13],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"app-url\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-navbar/nav/modules.hbs"
    }
  });
});