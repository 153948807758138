define("ui-nela/templates/components/ui-form/radio-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "WRybJlXo",
    "block": "{\"symbols\":[\"@selected\",\"@radioName\",\"&default\"],\"statements\":[[18,3,[[30,[36,2],null,[[\"button\"],[[30,[36,1],[\"ui-form/radio-group/radio-button\"],[[\"name\",\"selected\",\"onSelectRadio\"],[[32,2],[32,1],[30,[36,0],[[32,0],\"onSelectRadio\"],null]]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-form/radio-group.hbs"
    }
  });
});