define("ui-nela/components/ui-crud/list/list-group", ["exports", "ui-nela/templates/components/ui-crud/list/list-group"], function (_exports, _listGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    layout: _listGroup.default,
    tagName: 'ul',
    classNames: ['ui-crud__list__list-group', 'list-group']
  });
  _exports.default = _default;
});