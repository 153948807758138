define("nela-report/components/nela-report/pdf", ["exports", "nela-report/templates/components/nela-report/pdf"], function (_exports, _pdf) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _pdf.default,
    classNames: ['nela-report__pdf']
  });
});