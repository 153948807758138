define("ui-nela/templates/components/ui-sidebar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "JfJbFYoD",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"aside\"],[14,0,\"ui-sidebar__sidebar\"],[12],[2,\"\\n  \"],[10,\"ul\"],[14,0,\"ui-sidebar__sidebar__menu\"],[12],[2,\"\\n    \"],[10,\"li\"],[14,0,\"ui-sidebar__sidebar__menu__item ui-sidebar__sidebar__menu__item--open\"],[12],[2,\"\\n      \"],[11,\"span\"],[24,0,\"ui-sidebar__sidebar__menu__item__link\"],[24,\"role\",\"button\"],[4,[38,0],[[32,0],\"toggleMenu\"],null],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"ui-sidebar__sidebar__menu__item__icon fa fa-bars\"],[14,\"title\",\"Expandir menu\"],[12],[13],[2,\"\\n        \"],[10,\"span\"],[14,0,\"ui-sidebar__sidebar__menu__item__title\"],[12],[2,\"Recolher menu\"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[18,1,[[30,[36,2],null,[[\"item\"],[[30,[36,1],[\"ui-sidebar/menu-item\"],null]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-sidebar.hbs"
    }
  });
  _exports.default = _default;
});