define("ui-nela/components/ui-crud/view/section/tabs", ["exports", "ui-nela/templates/components/ui-crud/view/section/tabs"], function (_exports, _tabs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    layout: _tabs.default,
    tagName: 'ul',
    classNames: ['ui-card__header', 'card-header', 'nav']
  });
  _exports.default = _default;
});