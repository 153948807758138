define("ember-basic-dropdown/components/basic-dropdown", ["exports", "@ember-decorators/component", "ember-basic-dropdown/templates/components/basic-dropdown", "ember-basic-dropdown/utils/calculate-position", "require"], function (_exports, _component, _basicDropdown, _calculatePosition, _require) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _class2;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const ignoredStyleAttrs = ['top', 'left', 'right', 'width', 'height'];
  let BasicDropdown = _exports.default = (_dec = (0, _component.layout)(_basicDropdown.default), _dec2 = (0, _component.tagName)(''), _dec3 = Ember.computed, _dec(_class = _dec2(_class = (_class2 = class BasicDropdown extends Ember.Component {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "top", null);
      _defineProperty(this, "left", null);
      _defineProperty(this, "right", null);
      _defineProperty(this, "width", null);
      _defineProperty(this, "height", null);
      _defineProperty(this, "otherStyles", {});
      _defineProperty(this, "publicAPI", {});
      _defineProperty(this, "renderInPlace", false);
      _defineProperty(this, "verticalPosition", 'auto');
      // above | below
      _defineProperty(this, "horizontalPosition", 'auto');
      // auto-right | right | center | left
      _defineProperty(this, "matchTriggerWidth", false);
    }
    // Lifecycle hooks
    init() {
      super.init(...arguments);
      let publicAPI = this.updateState({
        uniqueId: Ember.guidFor(this),
        isOpen: this.initiallyOpened || false,
        disabled: this.disabled || false,
        actions: {
          open: this.open.bind(this),
          close: this.close.bind(this),
          toggle: this.toggle.bind(this),
          reposition: this.reposition.bind(this)
        }
      });
      this.dropdownId = this.dropdownId || `ember-basic-dropdown-content-${publicAPI.uniqueId}`;
      if (this.onInit) {
        this.onInit(publicAPI);
      }
    }
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      let oldDisabled = !!this._oldDisabled;
      let newDisabled = !!this.disabled;
      this._oldDisabled = newDisabled;
      if (newDisabled && !oldDisabled) {
        Ember.run.join(this, this.disable);
      } else if (!newDisabled && oldDisabled) {
        Ember.run.join(this, this.enable);
      }
    }
    willDestroy() {
      super.willDestroy(...arguments);
      if (this.registerAPI) {
        this.registerAPI(null);
      }
    }

    // CPs
    get destination() {
      return this._getDestinationId();
    }
    set destination(v) {
      return v === undefined ? this._getDestinationId() : v;
    }

    // Methods
    open(e) {
      if (this.isDestroyed) {
        return;
      }
      if (this.publicAPI.disabled || this.publicAPI.isOpen) {
        return;
      }
      if (this.onOpen && this.onOpen(this.publicAPI, e) === false) {
        return;
      }
      this.updateState({
        isOpen: true
      });
    }
    close(e, skipFocus) {
      if (this.isDestroyed) {
        return;
      }
      if (this.publicAPI.disabled || !this.publicAPI.isOpen) {
        return;
      }
      if (this.onClose && this.onClose(this.publicAPI, e) === false) {
        return;
      }
      if (this.isDestroyed) {
        return; // To check that the `onClose` didn't destroy the dropdown
      }
      this.setProperties({
        hPosition: null,
        vPosition: null,
        top: null,
        left: null,
        right: null,
        width: null,
        height: null,
        previousVerticalPosition: null,
        previousHorizontalPosition: null,
        otherStyles: {}
      });
      this.updateState({
        isOpen: false
      });
      if (skipFocus) {
        return;
      }
      let trigger = document.querySelector(`[data-ebd-id=${this.publicAPI.uniqueId}-trigger]`);
      if (trigger && trigger.tabIndex > -1) {
        trigger.focus();
      }
    }
    toggle(e) {
      if (this.publicAPI.isOpen) {
        this.close(e);
      } else {
        this.open(e);
      }
    }
    reposition() {
      if (!this.publicAPI.isOpen) {
        return;
      }
      let dropdownElement = document.getElementById(this.dropdownId);
      let triggerElement = document.querySelector(`[data-ebd-id=${this.publicAPI.uniqueId}-trigger]`);
      if (!dropdownElement || !triggerElement) {
        return;
      }
      this.destinationElement = this.destinationElement || document.getElementById(this.destination);
      let options = this.getProperties('horizontalPosition', 'verticalPosition', 'matchTriggerWidth', 'previousHorizontalPosition', 'previousVerticalPosition', 'renderInPlace');
      options.dropdown = this;
      let positionData = (this.calculatePosition || _calculatePosition.default)(triggerElement, dropdownElement, this.destinationElement, options);
      return this.applyReposition(triggerElement, dropdownElement, positionData);
    }
    applyReposition(trigger, dropdown, positions) {
      let changes = {
        hPosition: positions.horizontalPosition,
        vPosition: positions.verticalPosition,
        otherStyles: Object.assign({}, this.otherStyles)
      };
      if (positions.style) {
        if (positions.style.top !== undefined) {
          changes.top = `${positions.style.top}px`;
        }
        // The component can be aligned from the right or from the left, but not from both.
        if (positions.style.left !== undefined) {
          changes.left = `${positions.style.left}px`;
          changes.right = null;
          // Since we set the first run manually we may need to unset the `right` property.
          if (positions.style.right !== undefined) {
            positions.style.right = undefined;
          }
        } else if (positions.style.right !== undefined) {
          changes.right = `${positions.style.right}px`;
          changes.left = null;
        }
        if (positions.style.width !== undefined) {
          changes.width = `${positions.style.width}px`;
        }
        if (positions.style.height !== undefined) {
          changes.height = `${positions.style.height}px`;
        }
        Object.keys(positions.style).forEach(attr => {
          if (ignoredStyleAttrs.indexOf(attr) === -1) {
            if (changes[attr] !== positions.style[attr]) {
              changes.otherStyles[attr] = positions.style[attr];
            }
          }
        });
        if (this.top === null) {
          // Bypass Ember on the first reposition only to avoid flickering.
          let cssRules = [];
          for (let prop in positions.style) {
            if (positions.style[prop] !== undefined) {
              if (typeof positions.style[prop] === 'number') {
                cssRules.push(`${prop}: ${positions.style[prop]}px`);
              } else {
                cssRules.push(`${prop}: ${positions.style[prop]}`);
              }
            }
          }
          dropdown.setAttribute('style', cssRules.join(';'));
        }
      }
      this.setProperties(changes);
      this.set('previousHorizontalPosition', positions.horizontalPosition);
      this.set('previousVerticalPosition', positions.verticalPosition);
      return changes;
    }
    disable() {
      if (this.publicAPI.isOpen) {
        this.publicAPI.actions.close();
      }
      this.updateState({
        disabled: true
      });
    }
    enable() {
      this.updateState({
        disabled: false
      });
    }
    updateState(changes) {
      let newState = Ember.set(this, 'publicAPI', Ember.assign({}, this.publicAPI, changes));
      if (this.registerAPI) {
        this.registerAPI(newState);
      }
      return newState;
    }
    _getDestinationId() {
      let config = Ember.getOwner(this).resolveRegistration('config:environment');
      let id;
      if (config.environment === 'test' && typeof FastBoot === 'undefined') {
        if (true /* DEBUG */) {
          if (_require.default.has('@ember/test-helpers/dom/get-root-element')) {
            try {
              id = (0, _require.default)('@ember/test-helpers/dom/get-root-element').default().id;
            } catch (ex) {
              // no op
            }
          }
          if (!id) {
            let rootView = document.querySelector('#ember-testing > .ember-view');
            id = rootView ? rootView.id : undefined;
          }
          return id;
        }
      }
      id = config['ember-basic-dropdown'] && config['ember-basic-dropdown'].destination || 'ember-basic-dropdown-wormhole';
      // if (DEBUG && typeof FastBoot === 'undefined' && !this.renderInPlace) {
      //   assert(`You're trying to attach the content of a dropdown to an node with ID ${id}, but there is no node with that ID in the document. This can happen when your Ember app is not in control of the index.html page. Check https://ember-power-select.com/docs/troubleshooting for more information`, document.getElementById(id));
      // }
      return id;
    }
  }, _applyDecoratedDescriptor(_class2.prototype, "destination", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "destination"), _class2.prototype), _class2)) || _class) || _class);
});