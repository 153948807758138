define("ui-nela/templates/components/ui-crud", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "CfYxE6+1",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"ui-crud__body\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row h-100\"],[12],[2,\"\\n    \"],[18,1,[[30,[36,1],null,[[\"list\",\"view\"],[[30,[36,0],[\"ui-crud/list\"],null],[30,[36,0],[\"ui-crud/view\"],null]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-crud.hbs"
    }
  });
});