define("ui-nela/components/ui-crud/list/header/tabs/tab-btn", ["exports", "ui-nela/templates/components/ui-crud/list/header/tabs/tab-btn", "jquery"], function (_exports, _tabBtn, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _tabBtn.default,
    tagName: 'button',
    classNames: ['ui-crud__list__header__tabs__tab-btn', 'btn'],
    click() {
      this._super(...arguments);
      (0, _jquery.default)('.ui-crud__list__header__tabs__tab-btn').removeClass('btn-active');
      (0, _jquery.default)(`#${this.elementId}`).addClass('btn-active');
    }
  });
});