define("ui-nela/components/ui-modal/footer", ["exports", "ui-nela/templates/components/ui-modal/footer"], function (_exports, _footer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _footer.default,
    classNames: 'modal-footer',
    actions: {
      closeModal() {
        if (this.closeModal) {
          this.closeModal();
        }
      }
    }
  });
});