define("ui-nela/templates/components/ui-crud/list/list-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ry8nF+l3",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-crud/list/list-group.hbs"
    }
  });
  _exports.default = _default;
});