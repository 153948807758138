define("ui-nela/components/ui-form/group", ["exports", "ui-nela/templates/components/ui-form/group"], function (_exports, _group) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    layout: _group.default,
    classNames: ['ui-form-group'],
    classNameBindings: ['gridSize', 'gridOffset'],
    gridSize: Ember.computed('col', function () {
      let col = Ember.get(this, 'col');
      if (!col) {
        return 'col-12';
      }
      return col.split(' ').map(s => `col-${s}`).join(' ');
    }),
    gridOffset: Ember.computed('offset', function () {
      let col = Ember.get(this, 'offset');
      if (!col) {
        return '';
      }
      return col.split(' ').map(s => `offset-${s}`).join(' ');
    }),
    validationMessage: Ember.computed('validations.content.@each', function () {
      let validations = Ember.get(this, 'validations.content');
      if (!validations) {
        return;
      }
      let validation = validations.findBy('attribute', Ember.get(this, 'attr'));
      if (!validation) {
        return;
      }
      return validation.get('messages.firstObject');
    })
  });
  _exports.default = _default;
});