define("ui-nela/components/ui-sidebar/menu-item", ["exports", "ui-nela/templates/components/ui-sidebar/menu-item"], function (_exports, _menuItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _menuItem.default,
    tagName: 'li',
    classNames: ['ui-sidebar__sidebar__menu__item'],
    classNameBindings: ['submenuClass', 'visibilityControl'],
    showIf: true,
    visibilityControl: Ember.computed('showIf', function () {
      return this.get('showIf') ? '' : 'd-none';
    }),
    show: Ember.computed('showIf', function () {
      if (this.showIf === undefined) {
        return true;
      }
      return this.showIf;
    }),
    submenuClass: Ember.computed('useSubmenu', function () {
      return this.get('useSubmenu') ? 'ui-sidebar__sidebar__menu__item--with-submenu' : '';
    }),
    actions: {
      onUseSubmenu() {
        Ember.set(this, 'useSubmenu', true);
      }
    }
  });
});