define("ui-nela/helpers/ui-currency", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.uiCurrency = uiCurrency;
  function uiCurrency([currency] /*, hash*/) {
    return (currency || 0).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    });
  }
  var _default = _exports.default = Ember.Helper.helper(uiCurrency);
});