define("ui-nela/components/ui-page/header/breadcrumbs/crumb", ["exports", "ui-nela/templates/components/ui-page/header/breadcrumbs/crumb"], function (_exports, _crumb) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _crumb.default,
    classNames: ['ui-page__header__breadcrumbs__breadcrumbs-item']
  }).reopenClass({
    positionalParams: ['text', 'link', 'param']
  });
});