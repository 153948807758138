define("ui-nela/templates/components/ui-card/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "b9EaxRAA",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,2],null,[[\"title\",\"btnGroup\"],[[30,[36,1],[\"ui-card/header/title\"],null],[30,[36,1],[\"ui-card/header/btn-group\"],[[\"didRender\"],[[30,[36,0],[[32,0],\"onUseBtnGroup\"],null]]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-card/header.hbs"
    }
  });
  _exports.default = _default;
});