define("ember-concurrency/-task-group", ["exports", "ember-concurrency/utils", "ember-concurrency/-task-state-mixin", "ember-concurrency/-property-modifiers-mixin"], function (_exports, _utils, _taskStateMixin, _propertyModifiersMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TaskGroupProperty = _exports.TaskGroup = void 0;
  const TaskGroup = _exports.TaskGroup = Ember.Object.extend(_taskStateMixin.default, {
    isTaskGroup: true,
    toString() {
      return `<TaskGroup:${this._propertyName}>`;
    },
    _numRunningOrNumQueued: Ember.computed.or('numRunning', 'numQueued'),
    isRunning: Ember.computed.bool('_numRunningOrNumQueued'),
    isQueued: false
  });
  let TaskGroupProperty = _exports.TaskGroupProperty = void 0;
  if (true) {
    _exports.TaskGroupProperty = TaskGroupProperty = class {};
  } else {
    _exports.TaskGroupProperty = TaskGroupProperty = class extends _utils._ComputedProperty {};
  }
  (0, _utils.objectAssign)(TaskGroupProperty.prototype, _propertyModifiersMixin.propertyModifiers);
});