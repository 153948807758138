define("ui-nela/components/ui-skeleton/avatar", ["exports", "ui-nela/templates/components/ui-skeleton/avatar"], function (_exports, _avatar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _avatar.default,
    classNames: ['ui-skeleton__avatar', 'ui-skeleton__animated']
  });
});