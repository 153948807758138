define("ui-nela/templates/components/ui-sidebar/menu-item/submenu/submenu-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "bJBWHC48",
    "block": "{\"symbols\":[\"@link\",\"@text\",\"@linkTo\"],\"statements\":[[6,[37,0],[[32,0,[\"show\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,0],[[32,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"link-to\",[[24,0,\"text-truncate\"],[16,\"title\",[32,2]]],[[\"@route\"],[[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[32,2]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"a\"],[15,6,[32,1]],[14,0,\"text-truncate\"],[15,\"title\",[32,2]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[2,\"\\n      \"],[1,[32,2]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-sidebar/menu-item/submenu/submenu-item.hbs"
    }
  });
});