define("ui-nela/templates/components/ui-skeleton/text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "1H9hQOKF",
    "block": "{\"symbols\":[\"@size\",\"&default\"],\"statements\":[[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,0,[\"counter\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[27,[32,2]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,2,[[30,[36,1],null,[[\"Line\"],[[30,[36,0],[\"ui-skeleton/text/line\"],[[\"size\"],[[32,1]]]]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"ui-skeleton/text/line\",[],[[\"@size\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\",\"if\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-skeleton/text.hbs"
    }
  });
});