define("ui-nela/templates/components/ui-navbar/logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "uzKJf3YS",
    "block": "{\"symbols\":[\"@route\"],\"statements\":[[8,\"link-to\",[[24,0,\"ui-navbar__logo\"]],[[\"@route\"],[[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"img\"],[14,\"src\",\"https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grfjqezlbdq4/b/nela-cdn/o/voors%2Fimages%2Flogo-voors-black.svg\"],[14,\"alt\",\"Voors\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-navbar/logo.hbs"
    }
  });
  _exports.default = _default;
});