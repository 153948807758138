define("ui-nela/templates/components/ui-navbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "SxaoblQF",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,1],null,[[\"brand\",\"logo\",\"nav\"],[[30,[36,0],[\"ui-navbar/brand\"],null],[30,[36,0],[\"ui-navbar/logo\"],null],[30,[36,0],[\"ui-navbar/nav\"],null]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-navbar.hbs"
    }
  });
  _exports.default = _default;
});