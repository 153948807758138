define("nela-dataloader/components/nela-dataloader", ["exports", "nela-dataloader/templates/components/nela-dataloader", "ember-concurrency"], function (_exports, _nelaDataloader, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    layout: _nelaDataloader.default,
    tagName: '',
    // Services
    fetch: Ember.inject.service(),
    store: Ember.inject.service(),
    // Properties
    records: Ember.A(),
    // Life Cycle
    didInsertElement() {
      this._super(...arguments);
      this.loadRecords.perform();
    },
    didUpdateAttrs() {
      this._super(...arguments);

      /* Quando os filtros forem atualizados, carrega os dados novamente. */
      this.loadRecords.perform();
    },
    // Tasks
    loadRecords: (0, _emberConcurrency.task)(function* () {
      let data = this.filters || {};
      let records = Ember.A();
      let options = {
        data
      };
      if (this.host) {
        options.host = this.host;
      }
      try {
        if (this.endpoint && this.modelName) {
          records = yield this.store.loadRecords(this.modelName, this.endpoint, options);
        } else if (this.modelName) {
          records = yield this.store.query(this.modelName, data);
        } else if (this.endpoint) {
          records = yield this.fetch.request(this.endpoint, options);
        } else {
          Ember.debug('Ops! Para utilizar o NelaDataloader é necessário informar um @modelName e/ou @endpoint');
        }
        if (records) {
          Ember.set(this, 'records', records.toArray());
        }
      } catch (e) {
        Ember.debug(`<NelaDataloader> :: loadRecords :: ${e}`);
      }
    }).restartable()
  });
  _exports.default = _default;
});