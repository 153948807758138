define("ui-nela/components/ui-card/body", ["exports", "ui-nela/templates/components/ui-card/body"], function (_exports, _body) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _body.default,
    classNames: ['ui-card__body', 'card-body']
  });
});