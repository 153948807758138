define("ui-nela/components/ui-logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // HTML attributes
    tagName: 'img',
    attributeBindings: ['src', 'width', 'alt', 'height'],
    // Multiline computed properties
    src: Ember.computed('logoType', function () {
      let type = Ember.get(this, 'logoType');
      let logo = Ember.get(this, 'logo');
      let url = Ember.get(logo, type);
      return url;
    }),
    // Lifecycle hooks
    init() {
      this._super(...arguments);
      this.logo = this.logo || {
        brand: "https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grfjqezlbdq4/b/nela-cdn/o/images/nela-with-logo.png",
        symbol: "https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grfjqezlbdq4/b/nela-cdn/o/images/nela-withe-symbol.png",
        logo: "https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grfjqezlbdq4/b/nela-cdn/o/images/nela-withe-logo.png"
      };
    }
  });
});