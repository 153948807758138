define("ui-nela/components/ui-navbar/nav/user/status", ["exports", "ui-nela/templates/components/ui-navbar/nav/user/status"], function (_exports, _status) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _status.default,
    tagName: 'ul',
    classNames: ['border-bottom', 'mx-3', 'pb-2'],
    statuses: Ember.A([{
      label: 'Online',
      type: 'online',
      value: 1
    }, {
      label: 'Ocupado',
      type: 'busy',
      value: 2
    }]),
    didInsertElement() {
      this._super(...arguments);
      Ember.set(this, 'showStatus', true);
    }
  });
});