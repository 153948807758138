define("ui-nela/services/swal", ["exports", "sweet-alert2"], function (_exports, _sweetAlert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    catch(response) {
      if (typeof response === 'string') {
        return this.error(response);
      }
      if (response.errors && response.errors.length) {
        let messages = '';
        response.errors.forEach(error => {
          if (error.detail) {
            messages += `${error.detail}\n`;
          } else {
            messages += `${error.title}\n`;
          }
        });
        return this.error(messages);
      }
      if (response.payload) {
        return this.error(response.payload);
      }
      return this.error('Erro desconhecido');
    },
    close() {
      return _sweetAlert.default.close();
    },
    confirm() {
      let title = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return _sweetAlert.default.fire({
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sim',
        icon: 'question',
        reverseButtons: true,
        showCancelButton: true,
        title,
        ...options
      });
    },
    error() {
      let text = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return _sweetAlert.default.fire({
        icon: 'error',
        title: 'Ops',
        text,
        ...options
      });
    },
    fire() {
      let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return _sweetAlert.default.fire({
        cancelButtonText: 'Cancelar',
        reverseButtons: true,
        ...options
      });
    },
    info() {
      let text = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return _sweetAlert.default.fire({
        icon: 'info',
        text,
        ...options
      });
    },
    loading() {
      let text = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return _sweetAlert.default.fire({
        imageUrl: '/ui-nela/images/bx_loader.gif',
        showCancelButton: false,
        showConfirmButton: false,
        title: 'Aguarde',
        text,
        ...options
      });
    },
    success() {
      let text = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return _sweetAlert.default.fire({
        icon: 'success',
        title: 'Pronto',
        text,
        ...options
      });
    },
    validation() {
      let validations = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      let html = validations.messages.join('<br>');
      return _sweetAlert.default.fire({
        icon: 'warning',
        html,
        ...options
      });
    },
    warning() {
      let text = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return _sweetAlert.default.fire({
        icon: 'warning',
        title: 'Atenção',
        text,
        ...options
      });
    }
  });
  _exports.default = _default;
});