define("nela-report/templates/components/nela-report", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "k+doaIbk",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,2],null,[[\"Generate\",\"CardFilter\",\"Pdf\"],[[32,0,[\"generate\"]],[30,[36,1],[\"nela-report/card-filter\"],[[\"generate\"],[[32,0,[\"generate\"]]]]],[30,[36,1],[\"nela-report/pdf\"],[[\"pdf\",\"isLoading\"],[[35,0],[32,0,[\"generate\",\"isRunning\"]]]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"pdf\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "nela-report/templates/components/nela-report.hbs"
    }
  });
  _exports.default = _default;
});