define("nela-select/components/nela-select-basic", ["exports", "ember-concurrency", "ember-inflector"], function (_exports, _emberConcurrency, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Services
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    // Power Select Attributes
    renderInPlace: true,
    searchEnabled: true,
    placeholder: 'Selecione',
    loadingMessage: 'Buscando Registros...',
    searchMessage: 'Digite para pesquisar.',
    noMatchesMessage: 'Nenhum registro encontrado.',
    // Power Select Methods
    search(term) {
      this.findRecords.perform(term);
    },
    onOpen() {
      this.findRecords.perform();
    },
    // Concurrency Tasks
    findRecords: (0, _emberConcurrency.task)(function* (term) {
      // Caso o método esteja sendo utilizado pela pesquisa, adiciona um timeout.
      if (term) {
        yield (0, _emberConcurrency.timeout)(300);
      }
      let query = this.query || {};
      let endPoint = this.endPoint;
      let modelName = this.modelName;

      // Cria um endpoint baseado no modelName informado.
      if (!endPoint && modelName) {
        endPoint = '/' + (0, _emberInflector.pluralize)(Ember.String.camelize(modelName)) + '/ajax';
      }
      query = Object.assign(query, {
        term
      });
      let options = {};
      options.data = query;

      // Faz o select buscar na api de outro módulo
      const enviroment = Ember.getOwner(this).factoryFor('config:environment').class;
      if (this.host && enviroment) {
        options.host = enviroment.apiUrl[this.host];
      }
      let records = yield Ember.get(this, 'ajax').request(endPoint, options);
      if (this.selectProperty && !this.modelName) {
        records = records.map(o => o[this.selectProperty]);
      }
      Ember.set(this, 'options', records);
    }).restartable()
  });
});