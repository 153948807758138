define("ui-nela/components/ui-crud/view/section", ["exports", "ui-nela/components/ui-card", "ui-nela/templates/components/ui-crud/view/section"], function (_exports, _uiCard, _section) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _uiCard.default.extend({
    layout: _section.default,
    classNames: ['ui-crud__view__section']
  });
  _exports.default = _default;
});