define("ui-nela/components/ui-card/header/title", ["exports", "ui-nela/templates/components/ui-card/header/title"], function (_exports, _title) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    layout: _title.default,
    tagName: 'h6',
    classNames: ['ui-card__header__title', 'align-self-center', 'mr-auto', 'mb-0']
  });
  _exports.default = _default;
});