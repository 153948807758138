define("ui-nela/components/ui-block", ["exports", "ui-nela/templates/components/ui-block"], function (_exports, _uiBlock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    layout: _uiBlock.default,
    classNames: 'ui-block'
  });
  _exports.default = _default;
});