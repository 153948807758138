define("ui-nela/components/ui-form/checkbox", ["exports", "ui-nela/templates/components/ui-form/checkbox"], function (_exports, _checkbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _checkbox.default,
    classNames: ['checkbox'],
    actions: {
      onChange(e) {
        if (this.onChange) {
          this.onChange(e);
        } else {
          Ember.set(this, 'value', e.target.checked);
        }
      }
    }
  });
});