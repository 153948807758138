define("ui-nela/components/ui-form-renderer", ["exports", "ui-nela/templates/components/ui-form-renderer"], function (_exports, _uiFormRenderer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    layout: _uiFormRenderer.default,
    tagName: '',
    actions: {
      onLoadForm(submitTask) {
        this.set('submitTask', submitTask);
      }
    }
  });
  _exports.default = _default;
});