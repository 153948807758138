define("ui-nela/helpers/hide-if", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.hideIf = hideIf;
  function hideIf(_ref /*, hash*/) {
    let [condition] = _ref;
    return condition ? 'd-none' : '';
  }
  var _default = Ember.Helper.helper(hideIf);
  _exports.default = _default;
});