define("ui-nela/services/toast", ["exports", "sweet-alert2"], function (_exports, _sweetAlert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const toast = _sweetAlert.default.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 4000
  });
  var _default = _exports.default = Ember.Service.extend({
    close() {
      return toast.close();
    },
    error(title = '') {
      return toast.fire({
        title,
        icon: 'error'
      });
    },
    fire(options = {}) {
      return toast.fire(options);
    },
    info(title = '') {
      return toast.fire({
        title,
        icon: 'info'
      });
    },
    loading(title = 'Carregando...', options = {}) {
      options = {
        timer: null,
        ...options
      };
      return toast.fire({
        html: '<div class="spinner-border text-primary" role="status"/>',
        title,
        ...options
      });
    },
    success(title = '') {
      return toast.fire({
        title,
        icon: 'success'
      });
    },
    warning(title = '') {
      return toast.fire({
        title,
        icon: 'warning'
      });
    }
  });
});