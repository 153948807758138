define("ui-nela/components/ui-card", ["exports", "ui-nela/templates/components/ui-card"], function (_exports, _uiCard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    layout: _uiCard.default,
    classNames: ['ui-card', 'card'],
    attributeBindings: ['title']
  });
  _exports.default = _default;
});