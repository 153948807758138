define("ui-nela/components/ui-button-link", ["exports", "ui-nela/components/ui-button", "ui-nela/templates/components/ui-button-link"], function (_exports, _uiButton, _uiButtonLink) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _uiButton.default.extend({
    layout: _uiButtonLink.default,
    classNames: ['ui-button-link']
  });
  _exports.default = _default;
});