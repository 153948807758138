define("ui-nela/components/ui-container", ["exports", "ui-nela/templates/components/ui-container"], function (_exports, _uiContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _uiContainer.default,
    classNames: ['ui-container', 'container']
  });
});