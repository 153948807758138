define("ember-power-select/components/power-select/trigger", ["exports", "@ember-decorators/component", "ember-power-select/templates/components/power-select/trigger"], function (_exports, _component, _trigger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _class2;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let Trigger = _exports.default = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_trigger.default), _dec3 = Ember._action, _dec(_class = _dec2(_class = (_class2 = class Trigger extends Ember.Component {
    clear(e) {
      e.stopPropagation();
      this.select.actions.select(null);
      if (e.type === 'touchstart') {
        return false;
      }
    }
  }, _applyDecoratedDescriptor(_class2.prototype, "clear", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "clear"), _class2.prototype), _class2)) || _class) || _class);
});