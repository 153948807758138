define("ui-nela/components/ui-checkbox", ["exports", "ui-nela/templates/components/ui-checkbox"], function (_exports, _uiCheckbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _uiCheckbox.default,
    classNames: ['ui-checkbox'],
    actions: {
      handleChange(value, proxyEvent) {
        if (this.onChange) {
          this.onChange(value, proxyEvent);
        } else {
          this.toggleProperty('checked');
        }
      }
    }
  });
});