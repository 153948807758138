define("ui-nela/components/ui-card/header/btn-group", ["exports", "ui-nela/templates/components/ui-card/header/btn-group"], function (_exports, _btnGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _btnGroup.default,
    classNames: ['ui-card__header__btn-group', 'ml-auto', 'd-flex']
  });
});