define("ui-nela/templates/components/ui-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "kPIRvFpp",
    "block": "{\"symbols\":[\"@modalSize\",\"&default\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"modal-dialog \",[32,1]]]],[14,\"role\",\"document\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"modal-content\"],[12],[2,\"\\n    \"],[18,2,[[30,[36,2],null,[[\"title\",\"body\",\"footer\"],[[30,[36,1],[\"ui-modal/title\"],[[\"closeModal\"],[[30,[36,0],[[32,0],\"closeModal\"],null]]]],[30,[36,1],[\"ui-modal/body\"],null],[30,[36,1],[\"ui-modal/footer\"],[[\"closeModal\"],[[30,[36,0],[[32,0],\"closeModal\"],null]]]]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-modal.hbs"
    }
  });
});