define("ui-nela/modifiers/on-click-outside", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _emberModifier.modifier)(function onClickOutside(element, _ref, hash) {
    let [handler] = _ref;
    let eventName = hash.eventName || 'click';
    let exceptClass = (hash.exceptClass || '').split(',');
    let dataAttr = 1 + Math.floor((1000 - 1) * Math.random());
    element.setAttribute('data-outside', dataAttr);
    let eventFunction = event => {
      let isOutside = !event.target.closest(`[data-outside="${dataAttr}"]`);
      let isException = exceptClass.find(selector => {
        return event.target.classList.contains(selector);
      });
      if (!isException && isOutside && handler) {
        handler(event);
      }
    };
    document.addEventListener(eventName, eventFunction);
    return () => {
      document.removeEventListener(eventName, eventFunction);
    };
  });
  _exports.default = _default;
});