define("ui-nela/components/ui-h6", ["exports", "ui-nela/templates/components/ui-h6"], function (_exports, _uiH) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    layout: _uiH.default,
    tagName: 'h6'
  });
  _exports.default = _default;
});