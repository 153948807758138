define("nela-dataloader/templates/components/nela-dataloader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "sqic8T4l",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,0],null,[[\"task\",\"records\",\"error\",\"loading\"],[[32,0,[\"loadRecords\"]],[32,0,[\"records\"]],[32,0,[\"loadRecords\",\"last\",\"error\"]],[32,0,[\"loadRecords\",\"isRunning\"]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"hash\"]}",
    "meta": {
      "moduleName": "nela-dataloader/templates/components/nela-dataloader.hbs"
    }
  });
});