define("ui-nela/templates/components/ui-form/group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "/d3niKZS",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n  \"],[18,1,[[30,[36,1],null,[[\"label\",\"status\"],[[30,[36,0],[\"ui-form/group/label\"],null],[30,[36,0],[\"ui-form/group/status\"],[[\"message\"],[[32,0,[\"validationMessage\"]]]]]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-form/group.hbs"
    }
  });
});