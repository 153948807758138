define("ui-nela/components/ui-navbar/nav/user", ["exports", "ui-nela/templates/components/ui-navbar/nav/user"], function (_exports, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    layout: _user.default,
    tagName: 'li',
    classNames: ['ui-navbar__nav__user', 'dropdown']
  });
  _exports.default = _default;
});