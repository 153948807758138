define("ui-nela/components/ui-sidebar/menu-item/submenu", ["exports", "ui-nela/templates/components/ui-sidebar/menu-item/submenu"], function (_exports, _submenu) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _submenu.default,
    tagName: 'ul',
    classNames: 'ui-sidebar__sidebar__menu__item__submenu',
    didInsertElement() {
      this.set('isSubmenu', true);
    }
  });
});