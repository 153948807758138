define("ui-nela/templates/components/ui-page/header/breadcrumbs/crumb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "jQ+zl+fN",
    "block": "{\"symbols\":[\"@link\",\"@text\",\"@models\",\"@param\"],\"statements\":[[6,[37,0],[[32,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"link-to\",[],[[\"@route\",\"@model\"],[[32,1],[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[32,2]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[32,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"link-to\",[],[[\"@route\",\"@models\"],[[32,1],[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[32,2]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"link-to\",[],[[\"@route\"],[[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[32,2]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-page/header/breadcrumbs/crumb.hbs"
    }
  });
  _exports.default = _default;
});