define("ui-nela/components/ui-skeleton/text", ["exports", "ui-nela/templates/components/ui-skeleton/text"], function (_exports, _text) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _text.default,
    classNames: ['ui-skeleton__text'],
    counter: Ember.computed('lines', function () {
      return Array(this.lines || 1);
    })
  });
});