define("ui-nela/components/ui-navbar/nav/item/badge", ["exports", "ui-nela/templates/components/ui-navbar/nav/item/badge"], function (_exports, _badge) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    layout: _badge.default,
    classNames: ['ui-navbar__nav__item__badge', 'badge', 'rounded-circle'],
    classNameBindings: ['showBadge::d-none'],
    showBadge: Ember.computed('showIf', function () {
      return Boolean(this.showIf);
    })
  });
  _exports.default = _default;
});