define("nela-dataloader/templates/components/nela-paging/btn-load-more", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "XSsfSphC",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,2],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,1],[[35,0],\"Ver mais\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"text\",\"or\",\"if\"]}",
    "meta": {
      "moduleName": "nela-dataloader/templates/components/nela-paging/btn-load-more.hbs"
    }
  });
});