define("nela-mask/helpers/nela-masker", ["exports", "vanilla-masker"], function (_exports, _vanillaMasker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Helper.helper(function nelaMasker(_ref /*, hash*/) {
    let [value, pattern] = _ref;
    if (!value || !pattern) {
      Ember.debug('nela-masker :: É necessário informar um value e um pattern para o helper');
      return;
    }
    return _vanillaMasker.default.toPattern(value, pattern);
  });
  _exports.default = _default;
});