define("ui-nela/components/ui-form/radio-group/radio-button", ["exports", "ui-nela/templates/components/ui-form/radio-group/radio-button", "jquery"], function (_exports, _radioButton, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    layout: _radioButton.default,
    tagName: 'label',
    classNames: ['ui-radio-button'],
    // TODO: Remover observer
    // eslint-disable-next-line ember/no-observers
    onChangeSelected: Ember.observer('selected', function () {
      if (Ember.get(this, 'value') === Ember.get(this, 'selected')) {
        (0, _jquery.default)('input:radio').prop('checked', true);
      }
    }),
    actions: {
      selectRadio(value) {
        Ember.set(this, 'selected', value);
        this.onSelectRadio(value);
      }
    }
  });
  _exports.default = _default;
});