define("nela-datatable/components/nela-datatable-search", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="ml-2 mt-2 float-right">
    <div class="nela-datatable-input-group">
      <input class="form-control nela-datatable-input" value={{value}} oninput={{action "onInput" value="target.value"}} placeholder="Pesquisar...">
      {{#if value}}
        <button onclick={{action "clearSearch"}} class="btn nela-datatable-input-btn" title="Limpar Pesquisa">
          <i class="fa fa-times"></i>
        </button>
      {{else}}
        <button class="btn nela-datatable-input-btn">
          <i class="fa fa-search"></i>
        </button>
      {{/if}}
    </div>
  </div>
  */
  {
    "id": "OzX6m55c",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"ml-2 mt-2 float-right\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"nela-datatable-input-group\"],[12],[2,\"\\n    \"],[10,\"input\"],[14,0,\"form-control nela-datatable-input\"],[15,2,[34,1]],[15,\"oninput\",[30,[36,0],[[32,0],\"onInput\"],[[\"value\"],[\"target.value\"]]]],[14,\"placeholder\",\"Pesquisar...\"],[12],[13],[2,\"\\n\"],[6,[37,2],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"button\"],[15,\"onclick\",[30,[36,0],[[32,0],\"clearSearch\"],null]],[14,0,\"btn nela-datatable-input-btn\"],[14,\"title\",\"Limpar Pesquisa\"],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"fa fa-times\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"button\"],[14,0,\"btn nela-datatable-input-btn\"],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"fa fa-search\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"value\",\"if\"]}",
    "meta": {
      "moduleName": "nela-datatable/components/nela-datatable-search.hbs"
    }
  });
  let NelaDatatableSearchComponent = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, (_class = class NelaDatatableSearchComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "value", _descriptor, this);
    }
    onInput(value) {
      Ember.set(this, 'value', value);
      let onInput = this.args.onInput;
      onInput(value);
    }
    clearSearch() {
      Ember.set(this, 'value', '');
      let onInput = this.args.onInput;
      onInput(null);
    }
    onSearch() {
      let onInput = this.args.onInput;
      onInput(this.value);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "value", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onInput", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "onInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearSearch", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "clearSearch"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSearch", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "onSearch"), _class.prototype)), _class));
  _exports.default = NelaDatatableSearchComponent;
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, NelaDatatableSearchComponent);
});