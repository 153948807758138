define("ui-nela/components/ui-card/header", ["exports", "ui-nela/templates/components/ui-card/header"], function (_exports, _header) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _header.default,
    classNames: ['ui-card__header', 'card-header'],
    classNameBindings: ['useBtnGroup:d-flex'],
    actions: {
      onUseBtnGroup() {
        this.set('useBtnGroup', true);
      }
    }
  });
});