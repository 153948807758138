define("ui-nela/components/ui-sidebar/menu-item/submenu/submenu-item", ["exports", "ui-nela/templates/components/ui-sidebar/menu-item/submenu/submenu-item"], function (_exports, _submenuItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _submenuItem.default,
    tagName: 'li',
    classNames: ['ui-sidebar__sidebar__menu__item__submenu__item'],
    classNameBindings: ['visibilityControl'],
    showIf: true,
    show: Ember.computed('showIf', function () {
      if (this.showIf === undefined) {
        return true;
      }
      return this.showIf;
    }),
    visibilityControl: Ember.computed('showIf', function () {
      return this.showIf ? '' : 'd-none';
    })
  });
});