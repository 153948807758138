define("nela-dataloader/services/store", ["exports", "@ember-data/store", "ember-inflector"], function (_exports, _store, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let StoreService = (_dec = Ember.inject.service, (_class = class StoreService extends _store.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "fetch", _descriptor, this);
    }
    loadRecord(modelName, endpoint, options) {
      return this.fetch.request(endpoint, options).then(response => {
        let camelizeKey = Ember.String.camelize(modelName);
        let dasherizeKey = Ember.String.dasherize(modelName);
        let meta = response.meta;
        if (meta) {
          delete response.meta;
        }
        this.pushPayload(response);
        let responseRecord = response[camelizeKey] || response[dasherizeKey];
        if (!responseRecord) {
          throw 'Fomato dos dados de retorno é inválido. Root do JSON não encontrado';
        }
        let normalized = this.peekRecord(modelName, responseRecord.id);
        if (meta) {
          normalized.meta = meta;
        }
        return normalized;
      }).catch(e => {
        Ember.debug(`Nela Dataloader :: loadRecord :: ${e}`);
        throw e;
      });
    }
    loadRecords(modelName, endpoint, options) {
      return this.fetch.request(endpoint, options).then(response => {
        let model = (0, _emberInflector.pluralize)(modelName);
        let camelizeKey = Ember.String.camelize(model);
        let dasherizeKey = Ember.String.dasherize(model);
        if (Ember.isArray(response)) {
          response = {
            [`${camelizeKey}`]: response
          };
        }
        let responseArray = response[camelizeKey] || response[dasherizeKey] || response[modelName];
        if (!responseArray) {
          throw 'Formato dos dados de retorno é inválido. Root do JSON não encontrado';
        }
        let meta = response.meta;
        if (meta) {
          delete response.meta;
        }
        this.pushPayload(response);
        let normalizedResponse = responseArray.map(obj => {
          return this.peekRecord(modelName, obj.id);
        });
        if (meta) {
          normalizedResponse.meta = meta;
        }
        return normalizedResponse;
      }).catch(e => {
        Ember.debug(`Nela Dataloader :: loadRecords :: ${e}`);
        throw e;
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = StoreService;
});