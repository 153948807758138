define("nela-mask/components/nela-input-number", ["exports", "nela-mask/templates/components/nela-input-number", "vanilla-masker"], function (_exports, _nelaInputNumber, _vanillaMasker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.TextField.extend({
    layout: _nelaInputNumber.default,
    autocomplete: 'off',
    // Life Cycle
    didInsertElement() {
      this._super(...arguments);
      let {
        element
      } = this;
      element.addEventListener('input', this.inputHandler.bind(this), false);
    },
    // Methods
    inputHandler() {
      let {
        element
      } = this;
      element.value = _vanillaMasker.default.toNumber(element.value);
    }
  });
});