define("ui-nela/components/ui-form/label", ["exports", "ui-nela/templates/components/ui-form/label"], function (_exports, _label) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    layout: _label.default,
    tagName: 'label'
  });
  _exports.default = _default;
});