define("ember-power-select/templates/components/power-select/trigger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "SjwIEhEu",
    "block": "{\"symbols\":[\"@placeholder\",\"@placeholderComponent\",\"@select\",\"&default\",\"@extra\",\"@selectedItemComponent\",\"@allowClear\"],\"statements\":[[6,[37,4],[[32,3,[\"selected\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,4],[[32,6]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,0],[[32,6]],[[\"extra\",\"option\",\"select\"],[[30,[36,3],[[32,5]],null],[30,[36,3],[[32,3,[\"selected\"]]],null],[30,[36,3],[[32,3]],null]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"ember-power-select-selected-item\"],[12],[18,4,[[32,3,[\"selected\"]],[35,2]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,4],[[30,[36,6],[[32,7],[30,[36,5],[[32,3,[\"disabled\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"span\"],[24,0,\"ember-power-select-clear-btn\"],[4,[38,1],[\"mousedown\",[32,0,[\"clear\"]]],null],[4,[38,1],[\"touchstart\",[32,0,[\"clear\"]]],null],[12],[2,\"×\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,0],[[32,2]],[[\"placeholder\"],[[32,1]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"span\"],[14,0,\"ember-power-select-status-icon\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"on\",\"select\",\"readonly\",\"if\",\"not\",\"and\"]}",
    "meta": {
      "moduleName": "ember-power-select/templates/components/power-select/trigger.hbs"
    }
  });
});