define("ui-nela/templates/components/ui-skeleton", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "o7sLIvmH",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,1],null,[[\"Render\",\"Text\",\"Avatar\"],[[30,[36,0],[\"ui-skeleton/render\"],null],[30,[36,0],[\"ui-skeleton/text\"],null],[30,[36,0],[\"ui-skeleton/avatar\"],null]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-skeleton.hbs"
    }
  });
});